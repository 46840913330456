<template>
  <div class="flex flex-wrap items-center gap-2" data-test="links_builder">
    <div
      v-for="(entry, index) in entries"
      :key="entry.id"
      data-test="links_builder_remove_link_button"
    >
      <LabelButton
        @click="
          $emit(
            'update:modelValue',
            (links = links.filter((e, i) => i != index)),
          )
        "
        :variant="variant == 'round' ? 'default' : variant"
        :size="size"
        :color="entry.color || 'white'"
        :title="'Unlink ' + entry.name"
        :data-test="'link_' + $slugify(entry.name) + '_button'"
        class="w-full"
      >
        {{
          entry.name
            ? entry.name.length > 20
              ? entry.name.slice(0, 17) + "..."
              : entry.name
            : "Unnamed"
        }}
      </LabelButton>
    </div>
    <div class="flex items-center leading-normal">
      <LinksPopover v-model="links" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import LabelButton from "../../helpers/LabelButton.vue";

import { Coloring } from "../../mixins/Coloring";
import { Addon } from "@/components/mixins/Addon";

export default {
  mixins: [Coloring, Addon],
  components: {
    LabelButton,
    LinksPopover: defineAsyncComponent(() =>
      import("@/components/links/partials/LinksPopover.vue"),
    ),
  },
  data() {
    return {
      entries: [],
    };
  },
  mounted() {
    this.entries = this.links
      ?.map((id) => this.$store.getters.entries.find((e) => e.id == id))
      .filter((e) => e);
  },
  watch: {
    links: {
      handler(links) {
        this.entries = links
          ?.map((id) => this.$store.getters.entries.find((e) => e.id == id))
          .filter((e) => e);
      },
    },
  },
  computed: {
    links: {
      get() {
        return this.modelValue;
      },
      set(links) {
        this.$emit("update:modelValue", links);
      },
    },
  },
};
</script>

<style module lang="scss">
.popover {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 0.5rem;
  gap: 0.5rem;
}
.searchInputWrapper {
  @apply flex items-center;
  border: 1px solid var(--ps-base-border-color);
  padding: 0.25rem;
  border-radius: 0.25rem;
}
.outputDisplay {
  padding: 0 0.25rem;
}
</style>
