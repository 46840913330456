<template>
  <div class="flex min-h-14 items-center gap-x-4 gap-y-4">
    <div class="flex items-center gap-x-4">
      <ButtonComponent
        @click="$store.dispatch('selected', null)"
        variant="round"
        color="primary"
      >
        <XMarkIcon class="h-5 w-5" />
      </ButtonComponent>
      <span class="hidden text-xs font-bold uppercase md:inline-block">
        {{ $store.getters.selected.length }} entries selected
      </span>
    </div>

    <!-- Separator -->
    <div
      class="hidden sm:block sm:h-6 sm:w-px sm:bg-gray-200 dark:sm:bg-gray-800"
      aria-hidden="true"
    />

    <div class="flex flex-1 items-center justify-center gap-4">
      <StatusSettings
        v-model="bulkStatus"
        :full="true"
        :statuses="
          $merge(
            getOwnAvailableStatuses(entry),
            JSON.stringify(getOwnAvailableStatuses(entry)) !=
              JSON.stringify(getAvailableStatuses(entry))
              ? getAvailableStatuses(entry)
              : [],
          )
        "
        @update:modelValue="
          (status) => {
            selectedEntries.forEach((entry) => {
              setStatus(entry, status);
              $store.dispatch('push', {
                event: 'entry_update',
                params: { entry: entry },
                entry: entry,
              });
            }),
              $store.dispatch('selected', null),
              (bulkStatus = null);
          }
        "
        :size="size"
        color="secondary"
      >
        <template #button>
          <ButtonComponentNew title="Apply Status" :class="$style.buttonRound">
            <IconHelper name="circle-dashed" size="20" />
          </ButtonComponentNew>
        </template>
      </StatusSettings>
      <!-- <ButtonComponent :size="size" color="secondary"> -->
      <DatePicker
        ref="date"
        :showRecurrence="false"
        :autoSetOnClick="false"
        @update:modelValue="updateDate"
        :teleport="true"
      >
        <template #button>
          <ButtonComponentNew
            title="Apply Schedule Date"
            :class="$style.buttonRound"
          >
            <IconHelper name="calendar" size="20" />
          </ButtonComponentNew>
        </template>
      </DatePicker>
      <!-- </ButtonComponent> -->

      <LinksBuilder
        v-model="bulkModel.links"
        @update:modelValue="
          () => {
            selectedEntries.forEach((e) => {
              e.links = [...e.links, ...bulkModel.links];
              $store.dispatch('push', {
                event: 'entry_update',
                params: { entry: e },
                entry: e,
              });
            });
            $store.dispatch('selected', null);
            bulkModel.links = [];
          }
        "
      >
        <template #button>
          <ButtonComponentNew title="Apply Links" :class="$style.buttonRound">
            <IconHelper name="link-2-icon" size="20" />
          </ButtonComponentNew>
        </template>
      </LinksBuilder>
      <div class="md:flex md:flex-1 md:justify-end">
        <ButtonComponentNew
          @click="
            selectedEntries.forEach((e) => deleteEntry(e)),
              $store.dispatch('selected', null)
          "
          title="Delete selected entries"
          :class="$style.buttonRound"
          style="
            --ps-button-color: var(--ps-base-background-color);
            --ps-button-color-hover: var(--ps-base-tinted-color);
            --ps-button-bg-color: var(--ps-base-delete-color);
            --ps-button-bg-color-hover: var(--ps-base-delete-color);
          "
        >
          <TrashIcon class="h-5" aria-hidden="true" />
        </ButtonComponentNew>
      </div>
    </div>
  </div>
</template>

<script setup>
import LinksBuilder from "../links/partials/LinksBuilder.vue";
import DatePicker from "../builders/DatePicker.vue";
import { TrashIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { addonBlueprint } from "@/addonBlueprint";
</script>

<script>
import { Routining } from "../mixins/Routining";
export default {
  mixins: [Statusing, Scheduling, Routining],
  props: {
    size: {
      type: String,
      default: "md",
    },
  },
  data() {
    return {
      navigator: navigator,
      bulkStatus: null,
      bulkModel: {
        status_id: null,
        links: [],
        schedule: {
          date: "",
          time: null,
        },
      },
    };
  },
  computed: {
    entry() {
      if (this.$route.params.id2) {
        return this.entries.find((e) => e.id == this.$route.params.id2);
      }
      return this.entries.find((e) => e.id == this.$route.params.id);
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },
    selectedEntries() {
      return this.$store.getters.selected
        .map((id) => this.entries.find((entry) => entry.id == id))
        .filter((e) => e !== undefined);
    },
  },
  methods: {
    updateDate(val) {
      this.$store.getters.selected
        .map((id) =>
          this.$store.getters.entries.find((entry) => entry.id == id),
        )
        .forEach((e) => {
          if (!e.schedule) {
            e.schedule = addonBlueprint("schedule", e);
          }

          this.setEntrySchedule(e, {
            date: this.interpretDate({ date: val }),
            time: e.schedule?.time,
          });

          !e.temp
            ? this.$store.dispatch("push", {
                event: "entry_update",
                params: { entry: e },
                entry: e,
              })
            : null;
        });

      this.$store.dispatch("selected", null);
    },
    // updateTime(val) {
    //   if (this.entry.schedule === null) return;

    //   if (
    //     (val === null || (val && val.op && val.op === "null")) &&
    //     this.entry.schedule?.date === null
    //   ) {
    //     this.deleteAddon();
    //     return;
    //   }

    //   // TODO need to move this part over to Addon Mixin updateAddon()
    //   this.setEntrySchedule(this.entry, {
    //     date: this.entry.schedule?.date,
    //     time: this.interpretTime({ time: val }),
    //   });

    //   if (
    //     this.entry.schedule.time === null &&
    //     this.entry.schedule.date === null
    //   ) {
    //     this.deleteAddon();
    //   } else {
    //     !this.entry.temp
    //       ? this.$store.dispatch("push", {
    //           event: "entry_update",
    //           params: { entry: this.entry },
    //           entry: this.entry,
    //         })
    //       : null;
    //   }
    // },

    deleteEntry(entry = null) {
      if (entry === null) entry = this.entry;
      entry.deleted_at = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss");
      this.$store.getters.entries
        .filter((e) => e.links.includes(entry.id))
        .forEach((e) => {
          e.links.splice(
            e.links.findIndex((l) => l == entry.id),
            1,
          );
        });
      if (!entry.temp)
        this.$store.dispatch("push", {
          event: "entry_delete",
          params: { id: entry.id },
          entry: entry,
        });
      if (this.$route.params.id == entry.id) this.$router.back();
    },
  },
};
</script>

<style module lang="scss">
.buttonRound {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonRound from "@/styles/buttons.module.scss";

  @apply relative inline-flex items-center gap-x-2 rounded-full p-2 align-baseline shadow-sm transition-colors duration-200 ease-in-out focus-visible:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2;

  outline: 1px solid var(--ps-base-border-color);
}
</style>
