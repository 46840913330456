<template>
  <div class="flex flex-col">
    <TimeTrackingSettings :entry="entry" :class="$style.timeTrackingStarter" />

    <TimeTrackingListUserDropdown
      ref="time_tracking_list_user_dropdown"
      v-for="(time_trackings, user_id) in time_trackings_by_users"
      :key="user_id"
      :modelValue="time_trackings"
      :entry="entry"
      :user="users.find((u) => u.id == user_id)"
    />

    <!-- <TimeTrackingsRow
      :modelValue="time_trackings[index]"
      @update:modelValue="
        (time_tracking) => {
          if (time_tracking) {
            $store.dispatch('push', {
              event: 'time_tracking_update',
              params: { time_tracking: time_tracking },
              entry: entry,
              undo: 1,
            });
            time_trackings[index] = time_tracking;
          } else {
            $store.dispatch('push', {
              event: 'time_tracking_delete',
              params: { id: time_trackings[index].id },
              entry: entry,
              undo: 1,
            });
            time_trackings = time_trackings.filter((_, i) => i !== index);
          }
        }
      "
      v-for="(_, index) in time_trackings.sort(
        (a, b) =>
          $moment.utc(b.start_at).unix() - $moment.utc(a.start_at).unix(),
      )"
      :entry="entry"
      :key="index"
      :mode="mode"
    /> -->
  </div>
</template>

<script>
import TimeTrackingSettings from "@/components/timetrackings/TimeTrackingSettings.vue";
import TimeTrackingListUserDropdown from "./TimeTrackingListUserDropdown.vue";
export default {
  components: {
    TimeTrackingSettings,
    TimeTrackingListUserDropdown,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    entry: Object,
    mode: {
      type: String,
      default: "entry",
    },
  },
  computed: {
    time_trackings: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    time_trackings_by_users() {
      return this.time_trackings.reduce((acc, time_tracking) => {
        if (!acc[time_tracking.user_id]) {
          acc[time_tracking.user_id] = [];
        }
        acc[time_tracking.user_id].push(time_tracking);
        return acc;
      }, {});
    },
    users() {
      return this.$store.getters.users;
    },
  },
  mounted() {
    if (Object.keys(this.time_trackings_by_users).length == 1) {
      this.$refs.time_tracking_list_user_dropdown[0].open = true;
    }
  },
};
</script>

<style module lang="scss">
.timeTrackingStarter {
  composes: highlightSlightly from "@/styles/utilities.module.scss";
  margin-bottom: 0.5rem;
}
</style>
