<template>
  <div :class="$style.calendarHelper">
    <header class="flex flex-none items-center justify-between gap-x-2 pb-4">
      <div>
        <h1
          class="flex items-center gap-x-2 text-base font-semibold leading-6 text-neutral-900 dark:text-neutral-300"
        >
          <time v-if="mode != 'day'" :datetime="currentStart.format('YYYY-MM')">
            {{ currentStart.format("MMMM YYYY") }}
          </time>
          <time
            v-if="mode == 'day'"
            :datetime="currentStart.format('YYYY-MM-DD')"
            class="sm:hidden"
            >{{ currentStart.format("MMM DD, YYYY") }}
          </time>
          <time
            v-if="mode == 'day'"
            :datetime="currentStart.format('YYYY-MM-DD')"
            class="hidden sm:inline"
          >
            {{ currentStart.format("MMMM DD, YYYY") }}
          </time>
          <span
            v-if="mode == 'day'"
            class="hidden text-sm text-neutral-500 sm:inline dark:text-neutral-400"
          >
            {{ currentStart.format("dddd") }}
          </span>
        </h1>
      </div>
      <div class="flex items-center gap-x-8">
        <slot name="header" />
        <div class="relative flex items-center gap-2">
          <ButtonComponent
            @click="
              $refs.fullCalendar.getApi().prev(), retrieveDateSpanFromCalendar()
            "
            color="secondary"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </ButtonComponent>
          <ButtonComponent
            @click="
              $refs.fullCalendar.getApi().today(),
                retrieveDateSpanFromCalendar()
            "
            color="secondary"
            class="hidden focus:relative md:block"
          >
            Today
          </ButtonComponent>
          <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <ButtonComponent
            @click="
              $refs.fullCalendar.getApi().next(), retrieveDateSpanFromCalendar()
            "
            color="secondary"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </ButtonComponent>
        </div>
        <div class="hidden md:flex md:items-center">
          <Menu as="div" class="relative">
            <MenuButton type="button" class="focus-visible:outline-none">
              <ButtonComponent color="primary" data-test="calendar_mode_button">
                <template v-if="mode == 'day'">Day view</template>
                <template v-if="mode == 'week'">Week view</template>
                <template v-if="mode == 'month'">Month view</template>
                <!-- <template v-if="mode == 'year'">Year view</template> -->
                <ChevronDownIcon
                  class="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ButtonComponent>
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-neutral-700"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="$emit('mode', 'day')"
                      :class="[
                        active
                          ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300'
                          : 'text-neutral-700 dark:text-neutral-400',
                        'block px-4 py-2 text-sm',
                      ]"
                      data-test="calendar_mode_day"
                    >
                      Day view
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="$emit('mode', 'week')"
                      :class="[
                        active
                          ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300'
                          : 'text-neutral-700 dark:text-neutral-400',
                        'block px-4 py-2 text-sm',
                      ]"
                      data-test="calendar_mode_week"
                    >
                      Week view
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="$emit('mode', 'month')"
                      :class="[
                        active
                          ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300'
                          : 'text-neutral-700 dark:text-neutral-400',
                        'block px-4 py-2 text-sm',
                      ]"
                      data-test="calendar_mode_month"
                    >
                      Month view
                    </a>
                  </MenuItem>
                  <!-- <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400',
                        'block px-4 py-2 text-sm',
                      ]"
                      >Year view</a
                    >
                  </MenuItem> -->
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <!-- <div class="ml-6 h-6 w-px bg-gray-300" />
          <button
            type="button"
            class="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Add event
          </button> -->
        </div>
        <Menu as="div" class="relative ml-6 md:hidden">
          <MenuButton
            class="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500 focus-visible:outline-none"
          >
            <span class="sr-only">Open menu</span>
            <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-neutral-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-neutral-800 dark:bg-black dark:ring-neutral-700"
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    @click="$refs.fullCalendar.getApi().today()"
                    :class="[
                      active
                        ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300'
                        : 'text-neutral-700 dark:text-neutral-400',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Go to today</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    @click="$emit('mode', 'day')"
                    :class="[
                      active
                        ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300'
                        : 'text-neutral-700 dark:text-neutral-400',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Day view</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    @click="$emit('mode', 'week')"
                    :class="[
                      active
                        ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300'
                        : 'text-neutral-700 dark:text-neutral-400',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Week view</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    @click="$emit('mode', 'month')"
                    :class="[
                      active
                        ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300'
                        : 'text-neutral-700 dark:text-neutral-400',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Month view</a
                  >
                </MenuItem>
                <!-- <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-neutral-700 dark:text-neutral-400',
                      'block px-4 py-2 text-sm',
                    ]"
                    >Year view</a
                  >
                </MenuItem> -->
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </header>
    <FullCalendar
      ref="fullCalendar"
      v-if="isLazyMounted"
      :options="calendarOptionsComputed"
    >
      <template v-slot:eventContent="arg">
        <!-- {{ arg.event.title }} -->
        <!-- {{ entry.input.schema }}
        {{ getInterpretedEntry(getEntryByEvent(arg.event)).schedule }} -->
        <!-- {{ arg.event }} -->
        <!-- {{ arg }} -->
        <CalendarEntryDisplay
          :entry="getEntryByEvent(arg.event)"
          :event="arg.event"
          :class="[
            arg.view.type == 'dayGridMonth' ? 'h-[22px]' : '',
            $style.eventContent,
            `bg-${arg.event.backgroundColor}-50 hover:bg-${arg.event.backgroundColor}-100 dark:bg-${arg.event.backgroundColor}-950 dark:hover:bg-${arg.event.backgroundColor}-900`,
          ]"
        />
      </template>
      <template v-slot:dayHeaderContent="arg">
        <div class="flex items-center gap-x-0.5 text-xs font-medium">
          <span :class="$style.dayHeaderContent_dayName">
            {{ $moment(arg.date).format("ddd.") }}
          </span>
          <span
            v-if="arg.view.type != 'dayGridMonth'"
            :class="{
              'bg-neutral-950 text-white': $moment(arg.date).isSame(
                $moment(),
                'day',
              ),
            }"
            class="flex h-5 w-5 items-center justify-center rounded-full text-center text-xs"
          >
            {{ $moment(arg.date).format("DD") }}
          </span>
        </div>
      </template>
      <template v-slot:slotLabelContent="arg">
        <span
          v-if="
            $moment(arg.date).format('mm') == '00' &&
            Math.abs(getTimeDifferenceInMinutes(arg.date)) > 5
          "
          class="pr-0.5 text-xs text-neutral-700 dark:text-neutral-500"
        >
          {{ $moment(arg.date).format("HH:mm") }}
        </span>
      </template>
      <template v-slot:allDayContent="arg">
        <span class="text-xs text-neutral-700 dark:text-neutral-500">
          {{ arg.text }}</span
        >
      </template>
      <template v-slot:nowIndicatorContent="arg">
        <div v-if="arg.isAxis" :class="$style.nowIndicator">
          {{ $moment.utc(arg.date).local().format("HH:mm") }}
        </div>
      </template>
      <template v-slot:dayCellContent="arg">
        <span
          v-if="arg.view.type == 'dayGridMonth'"
          :class="[
            $style.slotDayCellContent_header,
            $moment(arg.date).isSame($moment(), 'day') &&
              $style.slotDayCellContent_header_today,
          ]"
        >
          {{ $moment(arg.date).format("DD") }}</span
        >
        <DropZone
          :disableEdgeDetection="true"
          :dropSchema="{
            ...entry.input?.schema,
            ...{
              schedule: {
                date: $moment(arg.date).format('YYYY-MM-DD'),
              },
            },
          }"
          class="h-full w-full"
          :data-date="$moment(arg.date).format('YYYY-MM-DD')"
          data-test="calendar-day-cell-droppable"
        />
      </template>
      <template v-slot:slotLaneContent="arg">
        <div
          v-if="arg.view.type == 'timeGridWeek'"
          class="grid h-full grid-cols-7"
        >
          <DropZone
            v-for="day in [0, 1, 2, 3, 4, 5, 6]"
            :key="day"
            :disableEdgeDetection="true"
            @onDrop_disabled_test="(schema) => onDrop(schema, group)"
            :dropSchema="{
              ...entry.input?.schema,
              ...{
                schedule: {
                  date: $moment(calendarActiveStart)
                    .add(day, 'days')
                    .format('YYYY-MM-DD'),
                  time: $moment()
                    .set('hour', 0)
                    .set('minutes', 0)
                    .set('seconds', 0)
                    .set('milliseconds', arg.time.milliseconds)
                    .utc()
                    .format('HH:mm:ss'),
                },
              },
            }"
            class="h-full"
            :data-date="
              $moment(calendarActiveStart).add(day, 'days').format('YYYY-MM-DD')
            "
            :data-time="
              $moment()
                .set('hour', 0)
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', arg.time.milliseconds)
                .utc()
                .format('HH:mm:ss')
            "
            data-test="calendar-slot-lane-droppable"
          />
        </div>
        <div
          v-else-if="arg.view.type == 'timeGridThreeDay'"
          class="grid h-full grid-cols-3"
        >
          <DropZone
            v-for="day in [0, 1, 2]"
            :key="day"
            :disableEdgeDetection="true"
            @onDrop_disabled_test="(schema) => onDrop(schema, group)"
            :dropSchema="{
              ...entry.input?.schema,
              ...{
                schedule: {
                  date: $moment(calendarActiveStart)
                    .add(day, 'days')
                    .format('YYYY-MM-DD'),
                  time: $moment()
                    .set('hour', 0)
                    .set('minutes', 0)
                    .set('seconds', 0)
                    .set('milliseconds', arg.time.milliseconds)
                    .utc()
                    .format('HH:mm:ss'),
                },
              },
            }"
            class="h-full"
            :data-date="
              $moment(calendarActiveStart).add(day, 'days').format('YYYY-MM-DD')
            "
            :data-time="
              $moment()
                .set('hour', 0)
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', arg.time.milliseconds)
                .utc()
                .format('HH:mm:ss')
            "
            data-test="calendar-slot-lane-droppable"
          />
        </div>
        <DropZone
          v-else
          :disableEdgeDetection="true"
          :dropSchema="{
            ...entry.input?.schema,
            ...{
              schedule: {
                date: $moment(calendarActiveStart).format('YYYY-MM-DD'),
                time: $moment()
                  .set('hour', 0)
                  .set('minutes', 0)
                  .set('seconds', 0)
                  .set('milliseconds', arg.time.milliseconds)
                  .utc()
                  .format('HH:mm:ss'),
              },
            },
          }"
          class="h-full w-full"
          :data-time="
            $moment()
              .set('hour', 0)
              .set('minutes', 0)
              .set('seconds', 0)
              .set('milliseconds', arg.time.milliseconds)
              .utc()
              .format('HH:mm:ss')
          "
          data-test="calendar-slot-lane-droppable"
        />
      </template>
    </FullCalendar>
    <Teleport to="body">
      <div
        v-if="showInputOverlay"
        @click="showInput = false"
        class="fixed inset-0 z-10"
      ></div>
      <Transition @enter="animationEnterInputPanel">
        <PanelHelper
          v-if="showInput"
          ref="input"
          class="fixed z-20 w-80"
          :style="inputPosition"
        >
          <InputDisplay
            ref="input_display"
            v-model="input"
            :focusOnMount="true"
            :entry="entry"
            :schema="inputSchemaDated"
            :position="position"
            :color="entry.color"
            :editable="input && editable"
            @created="showInput = false"
            @onCancel="showInput = false"
          />
        </PanelHelper>
      </Transition>
      <!-- <PanelHelper
        v-if="popoverEntry"
        class="fixed z-20 w-80"
        :style="popoverPosition"
      >
        <EntryDisplay :modelValue="popoverEntry" />
      </PanelHelper> -->
    </Teleport>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";

import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import momentPlugin from "@fullcalendar/moment";

import { Routing } from "@/components/mixins/Routing";
import { Scheduling } from "@/components/mixins/Scheduling";
import { Statusing } from "@/components/mixins/Statusing";

// import colors from "tailwindcss/colors";
import { Schema } from "@/components/mixins/Schema";
import { Applicators } from "@/components/mixins/Applicators";

import { defineAsyncComponent } from "vue";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarEntryDisplay: defineAsyncComponent(() =>
      import("./CalendarEntryDisplay.vue"),
    ),
  },
  mixins: [Scheduling, Statusing, Schema, Applicators, Routing],

  props: {
    dateSelected: String,
    entry: Object,
    entries: Array,
    mode: {
      type: String,
      default: "month",
    },
    groupIndex: Number,
    size: String,
    renderType: String,
    overrideEntryRenderType: String,
    schema: Object,
    color: {
      type: String,
      default: "neutral",
    },
    position: String,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    mode() {
      this.changeView(this.mode);
      this.$nextTick(() => {
        this.retrieveDateSpanFromCalendar();
        this.$onIdle(() => {
          this.renderEvents();
        });
      });
    },
    entries: {
      handler(n) {
        // console.log("entries updated", JSON.stringify(n));
        if (JSON.stringify(n) !== JSON.stringify(this.entriesCache)) {
          const changedEntries = n.filter((entry) => {
            const cacheEntry = this.entriesCache.find(
              (cacheEntry) => cacheEntry.id === entry.id,
            );
            return (
              !cacheEntry ||
              JSON.stringify(entry) !== JSON.stringify(cacheEntry)
            );
          });
          // console.log(changedEntries);
          if (changedEntries.length === 0) return;
          this.$onIdle(() => {
            // setTimeout(() => {
            const calendarApi = this.$refs.fullCalendar?.getApi();
            if (calendarApi) {
              changedEntries.forEach((entry) => {
                const event = calendarApi.getEventById(entry.id);
                if (event) {
                  event.remove();
                }
                calendarApi.addEvent(this.mapEntryToEvent(entry));
              });
            }
            this.entriesCache = JSON.parse(JSON.stringify(n));
            // }, 200);
          });
        }
      },
      deep: true,
    },
    showInput(val) {
      if (val) {
        this.showInputOverlay = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.showInputOverlay = true;
          }, 100);
        });
      } else {
        this.showInputOverlay = false;
      }
    },
    isLazyMounted(n) {
      if (n) {
        this.$nextTick(() => {
          this.retrieveDateSpanFromCalendar();

          this.renderEvents();

          this.resizeObserver = new ResizeObserver(() => {
            const windowWidth = window.innerWidth;
            if (windowWidth < 768 && this.mode === "week") {
              this.$refs.fullCalendar.getApi().changeView("timeGridThreeDay");
            } else {
              this.$refs.fullCalendar
                .getApi()
                .changeView(this.getViewForFC(this.mode));
            }
            this.$refs.fullCalendar.getApi().updateSize();
          });

          this.resizeObserver.observe(this.$el);
        });
      }
    },
    input() {
      this.setInputSchema();
    },
  },
  mounted() {
    this.entriesCache = JSON.parse(JSON.stringify(this.entries));

    // new ThirdPartyDraggable(document, {
    //   itemSelector: ["center"].includes(this.position)
    //     ? "[data-entry-id][data-position]:is([data-position='center'])"
    //     : "[data-entry-id][data-position]:is([data-position='modal'])",
    //   mirrorSelector: "#draggable-wrapper",
    //   eventData: function (eventEl) {
    //     return {
    //       create: false,
    //       id: eventEl.getAttribute("data-entry-id"),
    //     };
    //   },
    // });
    this.isLazyMounted = true;
  },
  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
  data() {
    return {
      entriesCache: [],
      currentStart: this.$moment(),
      calendarActiveStart: null,
      calendarActiveEnd: null,
      showInput: false,
      showInputOverlay: false,
      inputPosition: { left: 0, top: 0 },
      inputSchemaDated: [],
      clickedDate: null,
      clickedTime: null,
      slotDuration: "00:15:00",
      resizeObserver: null,
      isLazyMounted: false,
    };
  },
  methods: {
    renderEvents() {
      this.entriesCache.forEach((entry) => {
        this.$refs.fullCalendar.getApi().addEvent(this.mapEntryToEvent(entry));
      });
    },
    retrieveDateSpanFromCalendar() {
      const calendarApi = this.$refs.fullCalendar.getApi();
      const { activeStart, activeEnd } = calendarApi.view;
      this.calendarActiveStart = activeStart;
      this.calendarActiveEnd = activeEnd;
    },

    getSlotSize(event) {
      if (!event.start || !event.end) return 1;
      const start = this.$moment.utc(event.start);
      const end = this.$moment.utc(event.end);

      const duration = end.diff(start, "minutes");
      return duration / parseInt(this.slotDuration.split(":")[1]);
    },
    getTimeDifferenceInMinutes(date) {
      const currentTime = this.$moment();
      const argTime = this.$moment(date);

      // Set both times to the same date (today)
      const currentTimeToday = this.$moment().set({
        hour: currentTime.hour(),
        minute: currentTime.minute(),
        second: 0,
        millisecond: 0,
      });

      const argTimeToday = this.$moment().set({
        hour: argTime.hour(),
        minute: argTime.minute(),
        second: 0,
        millisecond: 0,
      });

      // Calculate the difference in minutes
      return argTimeToday.diff(currentTimeToday, "minutes");
    },
    getViewForFC(view) {
      switch (view) {
        case "day":
          return "timeGridDay";
        case "week":
          return "timeGridWeek";
        case "month":
        default:
          return "dayGridMonth";
      }
    },
    changeView(view) {
      this.$refs.fullCalendar.getApi().changeView(this.getViewForFC(view));
    },
    getEntryByEvent(event) {
      return this.$store.getters.entries.find((entry) => entry.id == event.id);
    },
    handleDatesSet(info) {
      // This event gets triggered whenever the view is changed.
      this.currentStart = this.$moment(info.view.currentStart);
    },
    animationEnterInputPanel(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done,
      });
    },
    mapEntryToEvent(entry) {
      const start =
        entry.schedule?.date +
        (entry.schedule?.time ? "T" + entry.schedule?.time + "Z" : "");
      const end =
        entry.schedule?.date +
        (entry.schedule?.time
          ? "T" +
            this.$moment
              .utc(
                entry.schedule?.date +
                  (entry.schedule?.time ? " " + entry.schedule?.time : ""),
              )
              .add(
                entry.schedule?.duration && entry.schedule.duration > 900
                  ? entry.schedule.duration
                  : 900,
                "seconds",
              )
              .format("HH:mm:ss") +
            "Z"
          : start);

      return {
        id: entry.id,
        title: entry.name + start + " - " + end,
        start: start,
        end: end,
        eventStartEditable: false,
        eventDurationEditable: true,
        backgroundColor: entry.color ? entry.color : "neutral",
      };
    },
    setInputSchema() {
      if (this.entry.input) {
        this.inputSchemaDated = { ...this.entry.input.schema };
      } else {
        this.inputSchemaDated = {};
      }

      if (!this.inputSchemaDated.schedule) {
        this.inputSchemaDated.schedule = {
          date: null,
          time: null,
        };
      }

      this.inputSchemaDated.schedule.date = this.clickedDate;
      this.inputSchemaDated.schedule.time = this.clickedTime;
    },
  },
  computed: {
    calendarOptionsComputed() {
      const isSmallScreen = window.innerWidth < 768; // You can adjust this breakpoint as needed

      return {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          momentPlugin,
        ],
        // droppable: true,
        // eslint-disable-next-line no-unused-vars
        dayCellDidMount: (info) => {
          // info.el.classList.add("bg-indigo-500");
          // console.log("huh?", info);
          // info.el.innerHTML = '<div class="h-full w-full" ></div>';
          // info.el.addEventListener("mouseover", (e) => {
          //   // console.log(info);
          //   console.log("WHAT");
          //   this.onMouseEnter(e, {
          //     id: this.entry?.id + "_" + info.date,
          //     schema: {
          //       ...this.entry.input.schema,
          //       ...{
          //         schedule: {
          //           date: this.$moment(info.date).format("YYYY-MM-DD"),
          //         },
          //       },
          //     },
          //   });
          // });
        },
        // dayCellWillUnmount(info) {
        //   // info.el.removeEventListener("mouseover"); // todo add this.
        //   info.el.innerHTML = "";
        // },
        initialView:
          isSmallScreen && this.mode === "week"
            ? "timeGridThreeDay"
            : this.getViewForFC(this.mode),

        events: [] /*JSON.parse(
          JSON.stringify(this.entries.map(this.mapEntryToEvent)),
        )*/,
        // eventTimeFormat: {
        //   // like '14:30:00'
        //   hour: "2-digit",
        //   minute: "2-digit",
        //   second: "2-digit",
        //   meridiem: false,
        // },
        // aspectRatio: 1,
        height: "60dvh",
        nowIndicator: true,
        datesSet: this.handleDatesSet,
        editable: true,
        eventStartEditable: false,
        eventDurationEditable: true,
        // eventBackgroundColor: "#e5e5e5",
        eventBorderColor: "transparent",
        slotDuration: this.slotDuration,
        dayMaxEventRows: 3,
        dayPopoverFormat: "MMM D.",
        scrollTime: this.$moment().add(-0.5, "hours").format("HH:mm:ss"),
        handleWindowResize: false,
        eventClick: (info) => {
          this.navigateTo(this.getEntryByEvent(info.event));
        },
        eventResize: (eventResizeInfo) => {
          // console.log(eventResizeInfo.event);
          eventResizeInfo.revert();
          const entry = this.entries.find(
            (entry) => entry.id == eventResizeInfo.event.id,
          );

          let schema = {};
          if (this.entry.input) {
            schema = JSON.parse(JSON.stringify(this.entry.input.schema));
          }

          const start = this.$moment.utc(eventResizeInfo.event.start);
          const end = this.$moment.utc(eventResizeInfo.event.end);

          const durationInSeconds = end.diff(start, "seconds");
          // console.log(durationInSeconds);

          schema.schedule = {
            ...schema.schedule,
            ...{
              date: start.format("YYYY-MM-DD"),
              time: start.format("HH:mm:ss"),
              duration: durationInSeconds,
            },
          };
          this.applyInput(this.inputSchema(schema, entry), entry);

          if (!entry.temp)
            this.$store.dispatch("push", {
              event: "entry_update",
              params: { entry: entry },
              entry: entry,
            });
        },
        headerToolbar: false /*{
          left: "prev,today,next",
          center: "title",
          right: "timeGridWeek,timeGridDay,dayGridMonth", // user can switch between the two
        }*/,
        // fixedMirrorParent: document.body,
        firstDay: 1,
        // Define custom view for 3 days in week mode
        views: {
          timeGridThreeDay: {
            type: "timeGrid",
            duration: { days: 3 },
            buttonText: "3 days",
          },
        },
        visibleRange: function (currentDate) {
          // if (this.$isMobile && this.mode === "week") {
          // Show only 3 days for smaller screens in week mode
          const start = this.$moment(currentDate).startOf("week");
          const end = this.$moment(start).add(2, "days");
          return { start, end };
          // } else {
          // Default behavior for other screens or modes
          // return null;
          // }
        },
        dateClick: (info) => {
          this.clickedDate = this.$moment
            .utc(info.date)
            .local()
            .format("YYYY-MM-DD");
          this.clickedTime = info.allDay
            ? null
            : this.$moment(info.date).utc().format("HH:mm:ss");

          this.showInput = true;

          this.setInputSchema();

          if (info.jsEvent.type == "touchend") {
            this.inputPosition = {
              left: info.jsEvent.changedTouches[0].clientX + "px",
              top: info.jsEvent.changedTouches[0].clientY + "px",
            };
          } else {
            this.inputPosition = {
              left: info.jsEvent.clientX + "px",
              top: info.jsEvent.clientY + "px",
            };
          }

          this.$nextTick(() => {
            if (this.$refs.input_display) {
              /**
               * This is used to prevent the input
               * form from overlapping on the right side
               */
              if (
                window.innerWidth <
                parseInt(this.inputPosition.left) +
                  this.$refs.input_display.$el.offsetWidth
              ) {
                this.inputPosition.left =
                  window.innerWidth -
                  this.$refs.input_display.$el.offsetWidth -
                  20;
              }
              /**
               * This is used to prevent the input
               * form from overlapping on the bottom side
               */

              if (
                window.innerHeight <
                parseInt(this.inputPosition.top) +
                  this.$refs.input_display.$el.offsetHeight
              ) {
                this.inputPosition.top =
                  window.innerHeight -
                  this.$refs.input_display.$el.offsetHeight -
                  window.innerHeight / 10;
              }

              this.inputPosition.left =
                Math.max(5, parseInt(this.inputPosition.left)) + "px";
              this.inputPosition.top =
                Math.max(5, parseInt(this.inputPosition.top)) + "px";
            }
          });
        },
      };
    },
    input: {
      get() {
        return this.entry.input;
      },
    },
    // eventsComputed() {
    //   return this.entriesCache.map((entry) => {
    //     const start =
    //       entry.schedule?.date +
    //       (entry.schedule?.time ? "T" + entry.schedule?.time + "Z" : "");
    //     const end =
    //       entry.schedule?.date +
    //       (entry.schedule?.time
    //         ? "T" +
    //           this.$moment
    //             .utc(
    //               entry.schedule?.date +
    //                 (entry.schedule?.time ? " " + entry.schedule?.time : ""),
    //             )
    //             .add(
    //               entry.schedule?.duration && entry.schedule.duration > 900
    //                 ? entry.schedule.duration
    //                 : 900,
    //               "seconds",
    //             )
    //             .format("HH:mm:ss") +
    //           "Z"
    //         : start);

    //     return {
    //       id: entry.id,
    //       title: entry.name + start + " - " + end,
    //       start: start,
    //       end: end,
    //       eventStartEditable: false,
    //       eventDurationEditable: true,
    //       backgroundColor: entry.color ? entry.color : "neutral",
    //     };
    //   });
    // },
  },
};
</script>

<style module lang="scss">
.calendarHelper {
  --ps-name-display-font-size: var(--ps-font-size-xs);
  --ps-name-display-line-height: var(--ps-line-height-sm);

  --ps-entry-sub-row-font-size: var(--ps-font-size-xs);
  --ps-entry-sub-row-line-height: 13px /*var(--ps-line-height-xs)*/;
}

.eventContent {
  --ps-entry-display-padding-y: 0;
  --ps-entry-display-padding-x: 0;

  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: var(--ps-entry-display-border-radius);
  overflow: hidden;
  // &Green {
  //   @apply bg-green-50 hover:bg-green-100 dark:bg-green-950 dark:hover:bg-green-900;
  // }
}

:global(.fc-timegrid-event) .eventContent {
  container: calendarEventContent / size;
}
.eventContent > * {
  @apply px-2;

  @media (max-width: 480px) {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.nowIndicator {
  @apply -mt-2 pr-1.5 text-right text-xs font-semibold;
  color: var(--ps-base-primary-color);
}

.slotDayCellContent_header {
  @apply pointer-events-none absolute right-0 top-1 mx-1 rounded-sm px-0.5 text-xs font-bold;

  color: var(--ps-base-secondary-color);

  &_today {
    background-color: var(--ps-base-primary-color);
    color: var(--ps-base-background-color);
  }
}

.dayHeaderContent_dayName {
  font-size: var(--ps-font-size-xs);
  line-height: var(--ps-line-height-xs);
  @media (max-width: 768px) {
    font-size: var(--ps-font-size-xxs);
    line-height: var(--ps-line-height-xs);
  }
  @media (max-width: 480px) {
    display: none;
  }
}
</style>
