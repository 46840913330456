<template>
  <div>
    <label
      :for="$slugify(label)"
      class="block text-sm font-medium leading-6 text-neutral-900 dark:text-neutral-300"
    >
      {{ label }}
    </label>
    <div :class="{ 'mt-2': label }">
      <input
        v-model="input"
        :id="$slugify(label)"
        :name="$slugify(label)"
        :type="type"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :required="required"
        :placeholder="placeholder"
        class="block w-full rounded-md border-0 px-3 py-1.5 text-neutral-900 shadow-sm outline-none ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-300"
        @blur="() => $emit('blur')"
        @keyup.enter="() => $emit('keyup-enter')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: null,
    placeholder: String,
    label: String,
    type: {
      type: String,
      default: "text",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    disabled: Boolean,
    required: Boolean,
  },
  mounted() {
    this.$emit("mounted");
  },
  computed: {
    input: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    focus() {
      this.$el.querySelector("input").focus();
    },
  },
};
</script>
