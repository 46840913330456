<template>
  <div>
    <!-- <Avatar :user_id="user_id" /> -->
    <button @click="open = !open" :class="$style.userHeader">
      <div class="flex items-center gap-1">
        <ChevronRightIcon size="16" v-if="!open" />
        <ChevronDownIcon size="16" v-else />
        <span>
          {{ user.name }}
        </span>
      </div>
      <div data-test="total_time">
        {{
          $moment
            .utc(
              time_trackings
                ?.filter((tt) => tt.end_at !== null)
                .map((tt) => {
                  if (
                    !$moment.utc(tt.start_at).isValid() ||
                    !$moment.utc(tt.end_at).isValid()
                  )
                    return 0;
                  return (
                    $moment.utc(tt.end_at).unix() -
                    $moment.utc(tt.start_at).unix()
                  );
                })
                .reduce((a, b) => a + b, 0) * 1000,
            )
            .format("H:mm:ss")
        }}
      </div>
    </button>

    <div v-if="open" :class="$style.timeTrackingRows">
      <TimeTrackingsRow
        v-for="(time_tracking, index) in time_trackings
          .filter((tt) => tt.end_at)
          .sort(
            (a, b) =>
              $moment.utc(b.start_at).unix() - $moment.utc(a.start_at).unix(),
          )"
        :time_tracking="time_tracking"
        :entry="entry"
        :key="index"
      />
      <!-- @update:modelValue="
          (time_tracking) => {
            if (time_tracking) {
              $store.dispatch('push', {
                event: 'time_tracking_update',
                params: { time_tracking: time_tracking },
                entry: entry,
                undo: 1,
              });
              time_trackings[index] = time_tracking;
            } else {
              $store.dispatch('push', {
                event: 'time_tracking_delete',
                params: { id: time_trackings[index].id },
                entry: entry,
                undo: 1,
              });
              time_trackings = time_trackings.filter((_, i) => i !== index);
            }
          }
        " -->
    </div>
  </div>
</template>

<script>
import TimeTrackingsRow from "./TimeTrackingsRow.vue";

import { ChevronRightIcon, ChevronDownIcon } from "lucide-vue-next";

export default {
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    entry: Object,
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    time_trackings: {
      get() {
        return this.modelValue;
      },
      set(time_trackings) {
        this.$emit("update:modelValue", time_trackings);
      },
    },
  },
  components: { ChevronDownIcon, ChevronRightIcon, TimeTrackingsRow },
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style module lang="scss">
.userHeader {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonRounded from "@/styles/buttons.module.scss";
  composes: buttonTintOnHover from "@/styles/buttons.module.scss";
  composes: buttonFlex from "@/styles/buttons.module.scss";
  @apply text-sm font-semibold;
  width: 100%;
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
}

.timeTrackingRows {
  @apply flex flex-col gap-0.5;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  margin-left: 14px;
}
</style>
