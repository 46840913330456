<template>
  <div>
    <div
      class="mx-auto w-full sm:max-w-xl md:max-w-2xl md:px-8 lg:max-w-3xl xl:max-w-5xl"
    >
      <div class="flex flex-col gap-4">
        <PanelHelper>
          <div class="px-4 sm:px-0">
            <h3
              class="text-base font-semibold leading-7 text-neutral-900 dark:text-neutral-200"
            >
              Subscription Settings
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-neutral-500">
              <!-- Personal details. -->
            </p>
          </div>

          <StripeCheckout
            v-if="showCheckout"
            ref="checkoutRef"
            :pk="publishableKey"
            :session-id="sessionId"
          />

          <div class="py-5">
            <div class="">
              <div class="flow-root">
                <div
                  class="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-neutral-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4 dark:divide-neutral-700"
                >
                  <div
                    v-for="tier in tiers"
                    :key="tier.id"
                    class="pt-16 lg:px-8 lg:pt-0 xl:px-14"
                  >
                    <h3
                      :id="tier.id"
                      class="text-base font-semibold leading-7 text-neutral-900 dark:text-neutral-300"
                    >
                      {{ tier.name }}
                    </h3>
                    <p
                      v-if="tier.price"
                      class="mt-6 flex items-baseline gap-x-1"
                    >
                      <span
                        class="text-5xl font-bold tracking-tight text-neutral-900 dark:text-neutral-300"
                        >{{ tier.price.monthly }}</span
                      >
                      <span
                        class="text-sm font-semibold leading-6 text-neutral-600 dark:text-neutral-500"
                      >
                        /month
                      </span>
                    </p>
                    <a
                      v-if="
                        tier.stripe_product_id &&
                        space.active_subscription.stripe_product_id ==
                          tier.stripe_product_id
                      "
                      :disabled="portalURL == null"
                      :href="portalURL"
                      :aria-describedby="tier.id"
                      :class="{
                        'cursor-pointer bg-neutral-600': portalURL != null,
                        'pointer-events-none cursor-default bg-neutral-200':
                          portalURL == null,
                      }"
                      class="mt-10 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-600 disabled:cursor-default"
                    >
                      <span v-if="portalURL">Manage Subscription</span>
                      <span v-else>Loading...</span>
                    </a>
                    <button
                      v-else-if="tier.stripe_product_id"
                      :disabled="
                        space.active_subscription.stripe_product_id ==
                        tier.stripe_product_id
                      "
                      @click="submit"
                      :aria-describedby="tier.id"
                      class="mt-10 block w-full rounded-md bg-neutral-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-600 disabled:cursor-default disabled:bg-neutral-200"
                    >
                      Subscribe
                    </button>
                    <p
                      class="mt-10 text-sm font-semibold leading-6 text-neutral-900 dark:text-neutral-300"
                    >
                      {{ tier.description }}
                    </p>
                    <ul
                      role="list"
                      class="mt-6 space-y-3 text-sm leading-6 text-neutral-600 dark:text-neutral-400"
                    >
                      <li
                        v-for="feature in tier.features"
                        :key="feature"
                        class="flex gap-x-3"
                      >
                        <CheckCircleIcon
                          class="h-6 w-5 flex-none text-neutral-600"
                          aria-hidden="true"
                        />
                        {{ feature }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PanelHelper>
      </div>
    </div>
  </div>
</template>
<script setup>
import { CheckCircleIcon } from "@heroicons/vue/20/solid";

const tiers = [
  {
    name: "Early Bird",
    id: "free_2023-12-30",
    stripe_product_id: null,
    href: "#",
    price: { monthly: "€0" },
    description:
      "Enjoy all of Pensive's features for free. Let it make you more productive.",
    features: [
      "Unlimited Entries",
      "Unlimited Statuses",
      "Unlimited Custom Fields",
      "Access to Schedules",
      "Access to Routines",
      "Access to Senses",
    ],
  },
  {
    name: "Supporter",
    id: "pro_2023-12-30",
    stripe_product_id: "prod_NUkkggOaQ2kHhf",
    href: "#",
    price: { monthly: "€5" },
    description: "Want to support Pensive? Here's a way to do it.",
    features: [
      "Everything from Free",
      "Support my work with a monthly coffee ☕️",
    ],
  },
];
</script>
<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import moment from "moment";

export default {
  name: "SpacePage",
  data() {
    this.publishableKey =
      process.env.NODE_ENV === "production"
        ? "pk_live_51MjPE1DONrNfdT6AfWgTdMSh6KRj05vePn6AY5Uq6IlCB45ERT7K5GJ7T8lcudkwDRNN4b2nonEj37XKiA8Ocr090005ihksck"
        : "pk_test_51MjPE1DONrNfdT6ATalHegQHUkO9XKz5sbJjPUeNMQDRZopnqV977FJhJByr7KyDJ9yMMJ3MLfKULNWZkH7SIWPX00eDL1Gy74";

    return {
      statuses: this.$store.getters.space?.statuses,

      moment: moment,
      showApiToken: false,
      sessionId: null,
      portalURL: null,
      loadingSubscriptions: true,
      showCheckout: false,
    };
  },

  mounted() {
    // if (process.env.NODE_ENV !== "production" || this.user.verified) {
    axios.get("stripe/session").then((response) => {
      this.sessionId = response.data.id;
    });
    axios.get("stripe/portal").then((response) => {
      this.portalURL = response.data.url;
    });
    this.$store.dispatch("retrieveSubscriptions").then(() => {
      this.loadingSubscriptions = false;
    });
    // }
  },
  watch: {
    space: {
      handler() {
        this.statuses = this.$store.getters.space.statuses;
      },
      deep: true,
    },
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.showCheckout = true;
      this.$nextTick(() => {
        this.$refs.checkoutRef.redirectToCheckout();
      });
    },
  },
};
</script>
