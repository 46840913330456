<template>
  <Teleport to="body">
    <Transition name="focus-transition" @enter="enter" @leave="leave">
      <div
        v-if="entry"
        @click="$store.dispatch('focus', null)"
        class="safe-area-inset-x fixed inset-0 left-0 top-0 flex transform-gpu flex-col items-center justify-center p-4"
        data-test="focus_wrapper"
      >
        <!-- <span
          class="absolute left-2 top-0 z-[-1] line-clamp-1 text-[10rem] uppercase leading-none text-white drop-shadow-xl lg:left-5 lg:top-2"
        >
          {{ entry?.name }}
        </span> -->

        <div
          ref="wrapper"
          @click.stop
          :class="{
            'grid-cols-focus-xs grid-rows-focus-xs grid-areas-focus-xs sm:grid-cols-focus-sm sm:grid-rows-focus-sm sm:grid-areas-focus-sm md:grid-cols-focus-md md:grid-rows-focus-md md:grid-areas-focus-md lg:grid-cols-focus-lg lg:grid-rows-focus-lg lg:grid-areas-focus-lg':
              !entry.output,
            'grid-cols-focus-io-xs grid-rows-focus-io-xs grid-areas-focus-io-xs sm:grid-cols-focus-io-sm sm:grid-rows-focus-io-sm sm:grid-areas-focus-io-sm md:grid-cols-focus-io-md md:grid-rows-focus-io-md md:grid-areas-focus-io-md lg:grid-cols-focus-io-lg lg:grid-rows-focus-io-lg lg:grid-areas-focus-io-lg':
              entry.output,
          }"
          class="max-w-screen grid max-h-full min-h-full w-full gap-5 overflow-hidden overflow-y-auto rounded-2xl bg-white/75 p-4 shadow-2xl ring-1 ring-neutral-100 backdrop-blur-sm sm:min-h-[75%] sm:overflow-y-hidden md:p-6 lg:max-w-4xl lg:p-12 dark:bg-black/50 dark:ring-neutral-700"
        >
          <div ref="entry" class="flex items-start grid-in-entry">
            <EntryDisplay
              v-model="entry"
              position="focus"
              class="max-h-[50svh]"
              size="4xl"
              :display="{
                links: false,
                routine: false,
                settings: false,
              }"
            />
            <ButtonComponent
              v-if="3 > 4"
              @click.stop="$store.dispatch('focus', null)"
              data-test="focus_wrapper_action_close"
            >
              <Minimize2Icon />
            </ButtonComponent>
          </div>
          <div class="flex items-center justify-center grid-in-timer">
            <CountdownHelper
              ref="timer"
              v-model="duration"
              :entry="entry"
              :color="entry.color"
              :autostart="false"
              @onStart="() => startFocus(entry)"
              @onStop="stopFocus"
              data-test="focus_wrapper_time_tracking"
              :class="{
                'text-neutral-900 dark:text-neutral-400': running,
                'text-neutral-200 dark:text-neutral-800': !running,
              }"
              class="max-h-full text-4xl transition-colors duration-300"
            />
          </div>
          <div
            class="hidden flex-col justify-end grid-in-time-trackings sm:flex"
          >
            <TimeTrackingsGrid
              ref="time_trackings"
              v-if="isInitialized"
              :initialize="initializeTimeTrackingsGrid"
              class="sm:w-72 sm:max-w-72 md:w-auto md:max-w-96"
            />
          </div>

          <div
            v-if="entry.output || entry.input"
            class="flex min-h-full flex-col gap-4 overflow-y-auto overflow-x-hidden grid-in-output"
          >
            <OutputDisplay
              v-if="entry.output"
              ref="output"
              v-model="entry.output"
              :entry="entry"
              :display="{ output: true, links: false }"
              :sorting="entry.output.sorting"
              :editable="false"
              :offset="page"
              :limit="limit"
              :showInput="!['gallery'].includes(entry.output.type)"
              :transitionSection="{
                enter: { translateY: ['100%', '0%'], opacity: [0, 1] },
                leave: { translateY: ['0%', '-100%'], opacity: [1, 0] },
              }"
              @mounted="pages = $refs.output.totalPages"
              position="focus"
              class="flex-1 px-2 md:overflow-auto md:hover-hover:overflow-hidden md:hover-hover:hover:overflow-auto"
              style="scrollbar-gutter: stable"
            >
              <template v-slot:entry-after="{ entry: _entry }">
                <div
                  v-if="
                    entry.output?.type == 'gallery' &&
                    (_entry.input || _entry.output)
                  "
                  @click.stop
                  class="mt-2 flex flex-col gap-y-5 border-t border-neutral-200 px-2 pb-2 dark:border-neutral-800"
                >
                  <OutputDisplay
                    v-if="_entry.output"
                    v-model="_entry.output"
                    :sorting="_entry.output.sorting"
                    :entry="_entry"
                    position="focus"
                    :editable="false"
                    :showInput="false"
                    class="mt-2"
                  />

                  <InputDisplay
                    v-if="_entry.input"
                    v-model="_entry.input"
                    :schema="_entry.input.schema"
                    :entry="_entry"
                    position="focus"
                    :editable="false"
                  />
                </div>
              </template>
              <!-- <template v-slot:output-after="{ pages }">
                <div v-if="pages > 1" class="flex items-center justify-between">
                  <ButtonComponent
                    @click="page > 0 ? page-- : null"
                    variant="minimal"
                  >
                    <ArrowLeftIcon />
                  </ButtonComponent>
                  <ul class="flex gap-[-4rem]">
                    <li
                      v-for="(_, index) in pages"
                      :key="index"
                      :class="{
                        'text-black dark:text-white': index == page,
                        'text-neutral-400 dark:text-neutral-800': index != page,
                      }"
                    >
                      <DotIcon size="48" />
                    </li>
                  </ul>
                  <ButtonComponent
                    @click="page < pages - 1 ? page++ : null"
                    variant="minimal"
                  >
                    <ArrowRightIcon />
                  </ButtonComponent>
                </div>
              </template> -->
            </OutputDisplay>

            <div
              v-if="pages > 1"
              class="flex items-center justify-between px-4"
            >
              <ButtonComponent
                @click="page > 0 ? page-- : null"
                :disabled="page == 0"
                data-test="focus_wrapper_action_prev_page"
                class="disabled:opacity-50"
              >
                <ArrowLeftIcon />
              </ButtonComponent>
              <ul class="flex">
                <li
                  v-for="(_, index) in pages"
                  :key="index"
                  :class="{
                    'text-black dark:text-white': index == page,
                    'text-neutral-400 dark:text-neutral-800': index != page,
                  }"
                >
                  <DotIcon size="24" stroke-width="8" />
                </li>
              </ul>
              <ButtonComponent
                @click="page < pages - 1 ? page++ : null"
                :disabled="page == pages - 1"
                data-test="focus_wrapper_action_next_page"
                class="disabled:opacity-50"
              >
                <ArrowRightIcon />
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
// import * as htmlToImage from "html-to-image";

import anime from "animejs";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DotIcon,
  Minimize2Icon,
} from "lucide-vue-next";
import { TimeTrackingsMixin } from "../timetrackings/mixins/TimeTrackingsMixin";
import CountdownHelper from "../helpers/CountdownHelper.vue";
import TimeTrackingsGrid from "../timetrackings/TimeTrackingsGrid.vue";
import { defineAsyncComponent, hydrateOnIdle } from "vue";
export default {
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    DotIcon,
    Minimize2Icon,
    CountdownHelper,
    TimeTrackingsGrid,

    OutputDisplay: defineAsyncComponent({
      loader: () => import("@/components/output/OutputDisplay.vue"),
      hydrate: () => hydrateOnIdle(),
    }),
  },
  mixins: [TimeTrackingsMixin],
  props: {
    modelValue: Object,
  },
  data() {
    return {
      page: 0,
      pages: 0,
      duration: null,
      running: false,
      startTrackingTimeout: null,

      isInitialized: false,
      initializeTimeTrackingsGrid: false,

      cachedScreen: null,
    };
  },
  mounted() {
    // if (this.$route.name == "focus") this.startFocus();
    // this.cachedScreen = { width: screen.width, height: screen.height };
    // screen.orientation.addEventListener("change", this.watchOrientation);
    window.addEventListener("resize", this.handleResize);

    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeUnmount() {
    // this.stopFocus();
    // screen.orientation.removeEventListener("change", this.watchOrientation);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  watch: {
    focus: function () {
      if (this.focus) {
        this.$onIdle(() => {
          this.isInitialized = true;
          // setTimeout(() => {
          this.initializeTimeTrackingsGrid = true;
          // }, 2000);
        });
      } else {
        this.isInitialized = false;
        this.initializeTimeTrackingsGrid = false;
      }
    },
    entry: {
      handler(n, o) {
        if (n && n?.id != o?.id) {
          this.startFocus(n);
        } else if (o && n?.id != o?.id) {
          this.stopFocus(o);
        }
      },
      deep: true,
    },
    $route: {
      handler() {
        this.$store.dispatch("focus", null);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    focus() {
      return this.$store.getters.focus;
    },
    entries() {
      return this.$store.getters.entries?.filter((e) => e.deleted_at === null);
    },
    entry() {
      return this.$store.getters.focus;
    },
    entry_by_route() {
      return this.entries?.find((e) => e.id == this.$route.params.id);
    },
    time_trackings: {
      get() {
        return this.entry?.time_trackings;
      },
      set(time_trackings) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.time_trackings = time_trackings;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    limit() {
      switch (this.entry?.output.type) {
        case "list":
          return null;
        case "board":
        case "gallery":
          return 1;
      }
      return null;
    },
  },
  methods: {
    // saveImg() {
    //   var node = this.$refs.wrapper;
    //   htmlToImage
    //     .toPng(node)
    //     .then(function (dataUrl) {
    //       var img = new Image();
    //       img.src = dataUrl;
    //       document.body.appendChild(img);
    //     })
    //     .catch(function (error) {
    //       console.error("oops, something went wrong!", error);
    //     });
    // },
    startFocus() {
      if (this.startTrackingTimeout) clearTimeout(this.startTrackingTimeout);

      this.page = 0;
      this.startTrackingTimeout = setTimeout(() => {
        if (this.entry) {
          this.startTimeTracking(this.entry);
          this.$refs.timer.running = true;

          this.running = true;
        }
        this.startTrackingTimeout = null;
      }, 1000);

      this.duration = this.entry.schedule?.duration
        ? this.entry.schedule.duration
        : 60 * 25;
    },
    stopFocus(entry) {
      this.stopTimeTracking(entry ? entry : this.entry);
      this.running = false;
    },
    enter(el, done) {
      if (this.$Cypress) {
        done();
        return;
      }
      anime({
        targets: el,
        scale: [0.75, 1],
        translateY: ["-100%", "0%"],
        complete: () => {
          done();
          // anime({
          //   targets: [
          //     this.$refs?.entry,
          //     this.$refs?.timer?.$el,
          //     this.$refs?.time_trackings?.$el,
          //     this.$refs?.output?.$el,
          //   ],
          //   opacity: [0, 1],
          //   translateY: ["100%", "0%"],
          //   delay: anime.stagger(100),
          // });
        },
      });
    },
    leave(el, done) {
      if (this.$Cypress) {
        done();
        return;
      }
      anime({
        targets: el,
        scale: [1, 0.75],
        translateY: ["0%", "-150%"],
        complete: done,
      });
    },
    watchOrientation() {
      if (
        navigator.userAgent.includes("Mobile") &&
        !this.$route.params.id2 &&
        (screen.height == this.cachedScreen.width ||
          screen.height == this.cachedScreen.height) &&
        (screen.width == this.cachedScreen.height ||
          screen.width == this.cachedScreen.width)
      ) {
        if (screen.orientation.type.startsWith("landscape")) {
          this.$store.dispatch("focus", this.entry_by_route);
        } else {
          this.$store.dispatch("focus", null);
        }
      }
    },
    handleResize() {
      this.cachedScreen = { width: screen.width, height: screen.height };
    },

    handleKeyUp(event) {
      if (this.entry) {
        if (
          event.target.tagName !== "INPUT" &&
          event.target.isContentEditable !== true
        ) {
          if (event.keyCode === 37 && !event.repeat) {
            this.page > 0 ? this.page-- : null;
          }
          if (event.keyCode === 39 && !event.repeat) {
            this.page < this.pages - 1 ? this.page++ : null;
          }
        }
      }
    },
  },
};
</script>
