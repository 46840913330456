import { addonBlueprint } from "@/addonBlueprint";
// eslint-disable-next-line no-unused-vars
import moment from "moment";

const settings = {
  modalOverlayOpeningPadding: 8,
  modalOverlayOpeningRadius: 4,
  scrollTo: { behavior: 'smooth', block: 'center' },
  /*cancelIcon: {
    enabled: true,
  }*/
}

// eslint-disable-next-line no-unused-vars
const navigation_id = document.documentElement.clientWidth < 1024 ? "#navigation_mobile" : "#navigation"

export function createTour(demo) {
  demo.tour = demo.$shepherd({
    useModalOverlay: true,
  });
  //   (Restart the tour with the <b>Reset Demo <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline">
  //   <path stroke-linecap="round" stroke-linejoin="round" d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z" />
  // </svg></b>
  //  Button at the Top)</small>. <br/>
  demo.tour.addSteps([
    /*{
      ...settings, ...{
        id: "tour_1",
        title: "Welcome to Pensive",
        text: `<strong>Awesome to have you here!</strong> <br/>
         This tour will guide you through the basic concepts of Pensive. <br/>
         You can skip this tour at any time by clicking on the 
         <strong>Skip Tour</strong> button, but I highly advise you to complete it. <small>
        
         <strong style="float:right; margin-top:1rem;">Let's get started.</strong><div style="clear:right;"></div>`,
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
      },
      when: {
        show: function () {
          if (window._paq)
            window._paq.push(['trackEvent', 'Tour', 'Step', 1])
        }
      },
    },
    {
      ...settings, ...{
        id: "tour_2",
        text: `Everything in Pensive is organized by <strong>entries</strong>.<br/><br/>
         An entry can be everything:<br/>
         · a <strong>Note</strong><br/>
         · a <strong>Task</strong><br/>
         · a <strong>View</strong> containing other Entries<br/><br/>
         But before you dive deep into complex entries, let's first check out a simple one.`,
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 2])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_3",

        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (document.documentElement.clientWidth < 1024) {
              document.querySelector("#left_sidebar_toggle_mobile").click()
            }
            setTimeout(function () {
              resolve();
            }, 500);
          });
        },
        canClickTarget: false,
        title: "This is the Menu. ",
        text: `Entries can be <strong>Anchored</strong> here for Quick Access. <br/>
         The first entry is always the <strong>Start Page</strong>. <br/>
         By pressing the <strong>&plus; Anchor</strong> button, you can create a new entry directly in the Menu. <br/>
         `,
        attachTo: { element: navigation_id, on: document.documentElement.clientWidth < 768 ? "bottom" : "right" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 3])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_4",
        text: `For this Tour, let's navigate to this <strong>Empty</strong> entry to play around and explore the basics. <br/>
         <strong>Try clicking on the entry in the Menu.</strong> <br/>
         `,
        attachTo: { element: navigation_id + " #empty", on: document.documentElement.clientWidth < 768 ? "bottom" : "right" },
        advanceOn: { selector: navigation_id + " #empty", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
      },
      when: {
        show: function () {
          if (window._paq)
            window._paq.push(['trackEvent', 'Tour', 'Step', 4])
        }
      },
    },
    {
      ...settings, ...{
        id: "tour_5",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.e('Empty').id)
              demo.$router.push({ name: "entry", params: { id: demo.e('Empty').id } })
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `Awesome! You switched the current page to the <strong>Empty</strong> entry.<br/>
         Right now, this entry is nothing more than a Jot, a Thought, a Note,<br/> 
         Let's change that right now!`,
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
      },

      when: {
        show: function () {
          if (window._paq)
            window._paq.push(['trackEvent', 'Tour', 'Step', 5])
        }
      },
    },
    {
      ...settings, ...{
        id: "tour_6",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `Let's imagine you want to make a Task out of this entry. <br/>
         Simply add a completion state to it by clicking on the <strong>&plus; Button</strong> right in front of the name.
         `,
        attachTo: { element: "#completion", on: "bottom" },
        advanceOn: { selector: "#completion", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 6])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_7",
        canClickTarget: false,
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)


            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `
         Great! Now this entry is <strong>completable</strong>! <br/>
         Now its time to rename it to something more meaningful. <br/>
         `,
        attachTo: { element: "#completion", on: "bottom" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 7])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_8",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)


            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `You can edit the name of the entry here. <br/>
         Since this is a Task now, let's rename it to <strong>My First Task</strong> or anything you like. <br/>
         <strong>Go ahead and edit the name of this entry.</strong><br/>
         
         `,
        attachTo: { element: "#name", on: "bottom" },
        advanceOn: { selector: "#name", event: "change" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            const vElement = this.getElement();
            if (vElement) vElement.focus = () => { };

            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 8])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_9",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";


            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `
         No Task is complete without a proper schedule date. <br/>
         <strong> Add a schedule for your entry </strong> <br />
         
         `,
        attachTo: { element: "#schedule", on: "bottom" },
        advanceOn: { selector: "#schedule", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 9])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_10",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);


            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        canClickTarget: false,
        text: `
         Splendid! Now the schedule of this entry is set to today. <br/>
         Of course, you are able to modify time and date by clicking on the according button, 
         but this is something you can do - without help - later. <br/>
         <br/>
         In general, you can edit any attribute in this area by clicking on it.
          `,
        attachTo: { element: "#header", on: "bottom" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 10])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_11",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);


            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `You did your very best to create a task for yourself to be completable today. <br/>
         <br/> 
         Now it's time to add some smaller subtasks to our task.<br/> 
         <br/>
        In order for this entry to show other entries, you need to add an <strong>output</strong>.<br/>
         <strong>Go ahead and add an output to this entry.</strong><br/>
         `,
        attachTo: { element: "#output", on: "top" },
        advanceOn: { selector: "#output", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 11])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_12",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);


            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        canClickTarget: false,

        text: `Now your entry is able to list other entries. <br/> 
         <strong>By default, it will show all entries <i>linked</i> to this entry</strong> <small> and this is also something you will change later.</small><br/>
         But right now, unfornutanely but obviously, there aren't any entries linked to this entry yet. <br/>
         Let's change that! <br/>`,
        attachTo: { element: "#output", on: "bottom" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 12])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_13",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);


            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `Of course, every Entry can also have an <strong>Input</strong>.<br/>
         Inputs enable the functionality to add entries inside of an entry. <br/>
         <strong>Go ahead and add an Input to this entry.</strong>`,
        attachTo: { element: "#input", on: "top" },
        advanceOn: { selector: "#input", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 13])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_14",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);




            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `Yeah! Now you are able to add sub-entries to our entry! <br/>
         As with the output, <strong>the default settings for input will <i>link</i> newly added entries to this entry.</strong> <br/>
         This way they will be shown in the Output, which (if you remember correctly) shows all entries <i>linked</i> to this Entry. <br/>
         <br/>
         <strong>Go ahead and add a sub-entry to this entry by entering its name into the input field.</strong> <br/>`,
        attachTo: { element: "#input", on: "top" },
        advanceOn: { selector: "#output", event: "change" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {

            const vElement = this.getElement();
            if (vElement) vElement.focus = () => { };

            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 14])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_15",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);



            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        canClickTarget: false,
        text: `<strong>Hurray, you created an Sub-Entry inside of your Entry!</strong> <br/>
         But wait, this entry is not completable out of the box? <br/>
         <br/>
         <strong>Let's change that now.</strong> <br/>`,
        attachTo: { element: "#output", on: "top" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 15])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_16",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo);
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);




            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `You can modify your Input and define more attributes that should go along with added Entries inside this Entry.<br/>
         <br/>
         <strong>Click on the Input to open the Input Builder.</strong> <br/>
         `,
        highlightClass: "p-1", // TODO this is fopr cypress testing, we need some space here
        attachTo: { element: "#input_builder", on: document.documentElement.clientWidth < 1024 ? "bottom" : "left" },
        advanceOn: { selector: "#input_builder > div > button", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 16])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_17",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);


            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            setTimeout(function () {
              if (document.querySelector("#input_builder > div > button:not([data-open='true'])"))
                document.querySelector("#input_builder > div > button:not([data-open='true'])").click()
              resolve();
            }, 50);
          });
        },
        canClickTarget: false,
        title: "Input Builder",
        text: `Here you can modify and add attributes which every entry entered here automatically comes along with. <br/>
         As you can see, the <strong>Links</strong> attribute is already added by default and will link newly added Entries to our Entry. <small>You already saw that in action</small>. <br/>`,
        attachTo: { element: "#input_builder_inner", on: document.documentElement.clientWidth < 1024 ? "bottom" : "left" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 17])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_18",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);


            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            setTimeout(function () {
              if (document.querySelector("#input_builder > div > button:not([data-open='true'])"))
                document.querySelector("#input_builder > div > button:not([data-open='true'])").click()
              resolve();
            }, 50);
          });
        },
        title: "Adding Completion",
        text: `As you can see, there are several different attributes that can be added.<br/>
         But for now, you want to add a <strong>Completion</strong> attribute to our Input. <br/>
         <strong>Add the Completion attribute to the Input.</strong>`,
        attachTo: { element: "#input_builder_add_completion", on: document.documentElement.clientWidth < 1024 ? "bottom" : "left" },
        advanceOn: { selector: "#input_builder_inner", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 18])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_19",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }



            setTimeout(function () {
              if (document.querySelector("#input_builder > div > button:not([data-open='true'])"))
                document.querySelector("#input_builder > div > button:not([data-open='true'])").click()
              resolve();
            }, 50);
          });
        },
        canClickTarget: false,
        text: `Cool, you've added a completion state of <strong>uncompleted</strong> to your Input. <br/>
         <br/>
         As you can see there are more attributes to choose from, but you can explore them later on your own! <br/>`,
        attachTo: { element: "#input_builder_inner", on: document.documentElement.clientWidth < 1024 ? "bottom" : "left" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 19])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_20",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }



            setTimeout(function () {
              if (document.querySelector("#input_builder_overlay"))
                document.querySelector("#input_builder_overlay").click()
              resolve();

            }, 50);
          });
        },
        text: `As you can see, a checkbox appeared in front of the input field. This indicates that newly created entries will automatically have one, too! <br/>
         <br/>
         <strong> Go Ahead, enter yet another entry into the input field.</strong> <br/>
         `,
        attachTo: { element: "#input", on: "top" },
        advanceOn: { selector: "#output", event: "change" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            const vElement = this.getElement();
            if (vElement) vElement.focus = () => { };

            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 20])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_21",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            setTimeout(function () {

              resolve();
            }, 50);
          });
        },
        canClickTarget: false,
        text: `Amazing! The new Sub Entry directly came with a checkbox! <br/>`,
        attachTo: { element: "#output", on: "top" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 21])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_22",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            setTimeout(function () {

              resolve();
            }, 50);
          });
        },
        text: `You are also able to modify the Output of your Entry. <br/>
        This way you can filter out entries that are not relevant to you. <br/>
        Right now, your entry shows all entries <i>linked</i> to it. Let's change that and add the condition to only show Entries that are completable! <br/>
        <br/>
        <strong>Click on the Output to open the Output Builder.</strong> <br/>`,
        attachTo: { element: "#output_builder", on: "bottom" },
        advanceOn: { selector: "#output_builder > div > button", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 22])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_23",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            setTimeout(function () {

              if (document.querySelector("#output_builder > div > button:not([data-open='true'])"))
                document.querySelector("#output_builder > div > button:not([data-open='true'])").click()
              resolve();
            }, 50);
          });
        },
        canClickTarget: false,

        text: `As with the Input Builder, you can modify the Output of your Entry here. <br/>
        Right now, you can see that the <strong>Links</strong> attribute is already added by default. <br/>
        `,
        attachTo: { element: "#output_builder_inner", on: "bottom" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 23])
          }
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_24",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            setTimeout(function () {

              if (document.querySelector("#output_builder > div > button:not([data-open='true'])"))
                document.querySelector("#output_builder > div > button:not([data-open='true'])").click()
              resolve();
            }, 50);
          });
        },
        text: `Click on the <strong>&plus; Add</strong> button to add a new attribute to the Output. <br/>`,
        attachTo: { element: "#filter_builder_add", on: "top" },
        advanceOn: { selector: "#filter_builder_add", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 24])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_25",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            setTimeout(function () {
              if (document.querySelector("#output_builder > div > button:not([data-open='true'])"))
                document.querySelector("#output_builder > div > button:not([data-open='true'])").click()
              setTimeout(function () {
                if (document.querySelector("#filter_builder_add"))
                  document.querySelector("#filter_builder_add").click()
                resolve();
              }, 25);
            }, 25);
          });
        },
        text: `There are a lot of different options to choose from. <br/>
        For now, you want to only see entries that are completable. <br/>
        <strong>Click on the <i>Completion Completed</i> option.</strong> <br/>`,
        attachTo: { element: '[data-option="optionpicker_options_option_completion_completed"]', on: "top" },
        advanceOn: { selector: "#output_builder_inner", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 25])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_26",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            setTimeout(function () {

              if (document.querySelector("#output_builder > div > button:not([data-open='true'])"))
                document.querySelector("#output_builder > div > button:not([data-open='true'])").click()

              setTimeout(function () {
                if (document.querySelector("#filter_builder_add"))
                  document.querySelector("#filter_builder_add").click()

                setTimeout(function () {
                  if (document.querySelector('[data-option="optionpicker_options_option_completion_completed"]'))
                    document.querySelector('[data-option="optionpicker_options_option_completion_completed"]').click()
                  resolve();
                }, 16);
              }, 16);
            }, 16);
          });
        },
        text: `For now, a filter checking if the completion state <strong>is set</strong> is enough. <br/>
        <br/>
        <strong>Go ahead and click on the <i>is set</i> option.</strong> <br/>`,

        attachTo: { element: '[data-option="optionpicker_options_option_eq_set"]', on: "top" },
        advanceOn: { selector: "#output_builder_inner", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 26])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_27",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            setTimeout(function () {
              if (document.querySelector("#output_builder > div > button:not([data-open='true'])"))
                document.querySelector("#output_builder > div > button:not([data-open='true'])").click()
              if (document.querySelector('[data-option="optionpicker_options_option_completion_completed"]'))
                document.querySelector('[data-option="optionpicker_options_option_completion_completed"]').click()
              if (document.querySelector('[data-option="optionpicker_options_option_eq_set"]'))
                document.querySelector('[data-option="optionpicker_options_option_eq_set"]').click()

              setTimeout(function () {
                if (demo.i(demo.our_id).output.filters.length != 2) {
                  demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
                }
              }, 25);

              resolve();
            }, 25);
          });
        },
        text: `As with the <strong>Input</strong>, you can customize the <strong>Output</strong> to your liking. <br/>`,
        attachTo: { element: "#output_builder_inner", on: "bottom" },
        canClickTarget: false,
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 27])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_28",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            if (demo.i(demo.our_id).output.filters.length != 2) {
              demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
            }
            setTimeout(function () {
              if (document.querySelector("#output_builder_overlay"))
                document.querySelector("#output_builder_overlay").click()

              resolve();
            }, 50);
          });
        },
        text: `Perfect! Now only entries that are completable are shown. <br/>
        <strong>You created your first todo list!</strong> <br/>`,
        attachTo: { element: "#output", on: "bottom" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 28])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_29",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            if (demo.i(demo.our_id).output.filters.length != 2) {
              demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
            }
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `Since your first sub entry is still linked to this entry, it is still shown further below. <br/>
        By listing <strong>leftover Entries</strong> here, you can make sure that you don't forget about them. <br/>`,
        attachTo: { element: "#linked", on: "top" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 29])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_30",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.our_id)
              demo.$router.push({ name: "entry", params: { id: demo.our_id } })
            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            if (demo.i(demo.our_id).output.filters.length != 2) {
              demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
            }


            if (document.documentElement.clientWidth < 1024) {
              document.querySelector("#left_sidebar_toggle_mobile").click()
            }

            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `Coming closer to the end of this tour, let's see what you've learned. <br/>
        <strong>Go back to the first menu point</strong>
        `,

        attachTo: { element: navigation_id + " #tasks-·-today", on: document.documentElement.clientWidth < 1024 ? "bottom" : "right" },
        advanceOn: { selector: navigation_id + " #tasks-·-today", event: "click" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 30])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_31",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.e("Tasks · Today").id)
              demo.$router.push({ name: "entry", params: { id: demo.e("Tasks · Today").id } })

            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            if (demo.i(demo.our_id).output.filters.length != 2) {
              demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
            }
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        classes: "translate-y-32",
        text: `This is an Entry with another <strong>Output</strong>, it is used as a View to show other entries<br/>
        <br/>
        Instead of showing linked entries with completion, this time it is showing all entries which are completable and scheduled for today.<br/>
        <small>This is completely customizable in the <strong>Output Builder</strong>, you can check it out and create your own views and filters later to show exactly what you want to see. </small>
        `,
        attachTo: { element: "#output", on: "top" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 31])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_32",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.e("Tasks · Today").id)
              demo.$router.push({ name: "entry", params: { id: demo.e("Tasks · Today").id } })

            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            if (demo.i(demo.our_id).output.filters.length != 2) {
              demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
            }
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `And - you guessed it - your Entry is also listed here, as it matches all the criteria of the Output. <br/>`,

        attachTo: { element: 'main [data-output-index="0"]', on: "bottom" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],

        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 32])
          },
        },
      }
    },*/
    {
      ...settings, ...{
        id: "tour_33",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.e("Tasks · Today").id)
              demo.$router.push({ name: "entry", params: { id: demo.e("Tasks · Today").id } })

            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }

            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            if (demo.i(demo.our_id).output.filters.length != 2) {
              demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
            }
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `The input also is slightly different, entries added here are completable and will directly become scheduled for today. 
        <br/>
        As with the Output Builder, you can use the <strong>Input Builder</strong> to create your own customized input fields. <br/>
        <br/>
        <strong>Try it and add another task for today</strong>`,
        attachTo: { element: "#input", on: "top" },
        advanceOn: { selector: "#output", event: "change" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            const vElement = this.getElement();
            if (vElement) vElement.focus = () => { };

            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 33])
          },
        },
      }
    },

    {
      ...settings, ...{
        id: "tour_34",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.e("Tasks · Today").id)
              demo.$router.push({ name: "entry", params: { id: demo.e("Tasks · Today").id } })

            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }
            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            if (demo.i(demo.our_id).output.filters.length != 2) {
              demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
            }

            if (demo.$store.getters.entries.filter(e => e.completion && e.schedule?.date == moment().format('YYYY-MM-DD')).length < 8) {
              demo.addEntry({
                name: "Task for today",
                completion: { completed: 0 },
                schedule: { date: moment().format('YYYY-MM-DD'), time: null },
              });
            }
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        text: `There it is, ready to be done today!`,

        canClickTarget: false,
        attachTo: { element: 'main [data-output-index="1"]', on: "bottom" },
        buttons: [
          {
            text: "Skip Tour",
            secondary: true,
            action: () => location.reload(), // demo.tour.complete,
          },
          {
            text: "Next",
            action: demo.tour.next,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 34])
          },
        },
      }
    },
    {
      ...settings, ...{
        id: "tour_35",
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            if (demo.$route.params.id != demo.e("Tasks · Today").id)
              demo.$router.push({ name: "entry", params: { id: demo.e("Tasks · Today").id } })

            if (!demo.i(demo.our_id).completion)
              demo.i(demo.our_id).completion = addonBlueprint('completion', demo.i(demo.our_id), demo)
            if (demo.i(demo.our_id).name == "Empty")
              demo.i(demo.our_id).name = "My First Task";
            if (!demo.i(demo.our_id).schedule)
              demo.i(demo.our_id).schedule = addonBlueprint('schedule', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).output)
              demo.i(demo.our_id).output = addonBlueprint('output', demo.i(demo.our_id), demo);
            if (!demo.i(demo.our_id).input)
              demo.i(demo.our_id).input = addonBlueprint('input', demo.i(demo.our_id), demo);

            if (!demo.$store.getters.entries.find(e => e.links.includes(demo.our_id))) {
              demo.addEntry({
                name: "Sub-Entry",
                links: [demo.our_id],
              })
            }

            if (!demo.i(demo.our_id).input.schema.completion) {
              demo.i(demo.our_id).input.schema = { ...demo.i(demo.our_id).input.schema, ...{ completion: { completed: 0 } } }
            }
            if (demo.$store.getters.entries.filter(e => e.links.includes(demo.our_id)).length < 2) {
              demo.addEntry({
                name: "Sub-Task",
                completion: { completed: 0 },
                links: [demo.our_id],
              })
            }

            if (demo.i(demo.our_id).output.filters.length != 2) {
              demo.i(demo.our_id).output.filters.push({ key: ['completion', 'completed'], op: 'is set', val: [] })
            }

            if (demo.$store.getters.entries.filter(e => e.completion && e.schedule?.date == moment().format('YYYY-MM-DD')).length < 8) {
              demo.addEntry({
                name: "Task for today",
                completion: { completed: 0 },
                schedule: { date: moment().format('YYYY-MM-DD'), time: null },
              })
            }
            setTimeout(function () {
              resolve();
            }, 50);
          });
        },
        title: "That's it!",
        text: `<strong>These were the basics of Pensive.</strong> <br/> 
        There are other things which make Pensive unique, but you will find out about them on your own. <br/>
        And if you have questions, you can check the <strong>help page</strong> and <strong>best practices</strong>!<br/>
        Check out the other entries in this demo to see what else is possible. <br/>
        <br/>
        <small>Remember: Everything you do here will be added to your account if you decide to sign up.</small>
        <br/>
        <br/>
        <strong>Thank you so much for taking the tour till the end!</strong> <br/>
        `,

        buttons: [
          {
            text: "Finish Tour",
            action: () => location.reload(), // demo.tour.complete,
          },
        ],
        when: {
          show: function () {
            if (window._paq)
              window._paq.push(['trackEvent', 'Tour', 'Step', 35])
          },
        },
      }
    },
  ]);
}
