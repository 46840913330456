<template>
  <div
    @contextmenu.stop="(e) => e.preventDefault()"
    class="fixed left-0 top-0 h-full w-full cursor-default"
    data-test="overlay"
  ></div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener("keyup", this.handleKeyup);
    //document.body.style.overflowY = "hidden";
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.handleKeyup);
    //document.body.style.overflowY = "auto";
  },
  methods: {
    handleKeyup(e) {
      if (e.key == "Escape") {
        this.$emit("click", e);
      }
    },
  },
};
</script>
