<template>
  <span
    :aria-label="status?.name || 'No status'"
    :class="[
      status
        ? `hover:bg-${status?.color}-500 ring-${
            status.color || 'neutral'
          }-500  ring-2 ring-offset-2 hover:ring-offset-4 dark:ring-offset-black dark:ring-${
            status.color || 'neutral'
          }-400 dark:hover:bg-${status?.color}-400 `
        : '',
      status?.type == 'done'
        ? `bg-${status?.color}-500 dark:bg-${status?.color}-400`
        : status
        ? 'bg-white dark:bg-black'
        : '',
      ['xs', 'sm'].includes(size) ? 'h-2 w-2' : 'h-3 w-3',
      'inline-block flex-shrink-0 rounded-full transition-all',
    ]"
  />
  <span class="sr-only ml-3 block truncate">{{
    status?.name || "No status"
  }}</span>
</template>

<script>
export default {
  props: {
    status: Object,
    size: {
      type: String,
      default: "md",
    },
  },
};
</script>
