<template>
  <div
    class="custom_fields_display flex max-w-full flex-wrap justify-stretch gap-2"
    @click="(e) => (!entry.temp ? e.stopPropagation() : null)"
  >
    <div
      v-for="(customField, index) in customFields"
      :key="index"
      :class="$style.customField"
    >
      <label class="select-none whitespace-nowrap text-gray-500 sm:text-sm">
        {{ customField.name }}
      </label>
      <div
        v-if="customField.type == 'rating'"
        class="flex flex-wrap gap-y-1"
        :data-test="'entry_cv_' + $slugify(customField.name)"
        :data-rating="customValues[index].value || null"
      >
        <!-- eslint-disable vue/no-mutating-props-->
        <ButtonComponent
          v-for="i in parseInt(
            customField.config?.count ? customField.config.count : 5,
          )"
          :key="i"
          @click="
            updateCustomValue(index, i != customValues[index].value ? i : null)
          "
          @mouseover="hoveredRating[customField.id] = i"
          @mouseleave="hoveredRating[customField.id] = null"
          variant="minimal"
          :class="{
            'opacity-50 grayscale hover:opacity-100 hover:grayscale-0':
              (hoveredRating[customField.id] == null &&
                i > customValues[index].value) ||
              (hoveredRating[customField.id] != null &&
                hoveredRating[customField.id] < i),
          }"
          :title="i.toString()"
          class="px-1"
          :data-test="'rating_' + i"
          :data-current="i == customValues[index].value || null"
        >
          <span
            v-html="customField.config?.char ? customField.config.char : '⭐️'"
          />
        </ButtonComponent>
      </div>
      <div
        v-else-if="customField.type == 'date'"
        :data-test="'entry_cv_' + $slugify(customField.name)"
        :data-date="customValues[index].value || null"
      >
        <!-- eslint-disable vue/no-mutating-props-->
        <DatePicker
          :modelValue="customValues[index].value"
          @update:modelValue="(value) => updateCustomValue(index, value)"
          :class="$style.input"
          data-table-column-target
        />
      </div>
      <div v-else>
        <input
          :type="customField.type"
          :step="customField.type == 'number' ? 'any' : null"
          v-model="customValues[index].value"
          @input="
            (event) => {
              customValues[index].value = event.target.value;
            }
          "
          @keyup.enter="(event) => event.target.blur()"
          @blur="updateCustomValue(index, customValues[index].value)"
          :class="[$style.input, $style[customField.type]]"
          :placeholder="
            customField.type.charAt(0).toUpperCase() + customField.type.slice(1)
          "
          :data-test="'entry_cv_' + $slugify(customField.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { customValueBlueprint } from "@/addonBlueprint";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    DatePicker: defineAsyncComponent(() =>
      import("@/components/forms/partials/DatePicker.vue"),
    ),
  },
  props: {
    customFields: Array,
    entry: Object,
  },
  data() {
    return {
      hoveredRating: [],
    };
  },
  computed: {
    customValues() {
      return this.customFields?.map((cf) => {
        return {
          ...customValueBlueprint(this.entry, cf),
          ...this.entry.custom_values.find((cv) => cv.custom_field_id == cf.id),
        };
      });
    },
  },
  methods: {
    updateCustomValue(index, value) {
      if (value) {
        this.customValues[index].value = value;

        const customValue = this.customValues[index];
        const existingCustomValue = this.entry.custom_values.find(
          (cv) => cv.id == customValue.id,
        );

        if (existingCustomValue) {
          existingCustomValue.value = customValue.value;
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.entry.custom_values.push(customValue);
        }
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.custom_values = this.entry.custom_values.filter(
          (cv) => cv.id != this.customValues[index].id,
        );
      }
      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
    },
  },
};
</script>

<style module lang="scss">
.customField {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.5rem;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--ps-base-border-color);
}

.input {
  @apply inline flex-1 border-0 bg-transparent p-0 sm:text-sm sm:leading-6;
  color: var(--ps-base-text-color);

  padding: 0.125rem 0.25rem;
  margin: -0.125rem -0.25rem;
  border-radius: 0.375rem;

  &::placeholder {
    color: var(--ps-base-secondary-color);
  }
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
  &:focus {
    outline: none;
  }
}

.number {
  width: 5rem;
  text-align: right;
}
</style>
