<template>
  <div>
    <PopoverHelper
      id="time_picker"
      :teleport="teleport"
      class="relative"
      data-test="time_picker"
    >
      <template #button>
        <ButtonComponent
          @click="
            (e) => {
              entry
                ? typeof value != 'string'
                  ? (cursor = moment.utc().add(1, 'hours').format('HH:00:00'))
                  : null
                : typeof value == 'undefined' || value === null
                ? (value = { op: 'now' })
                : null;
              // $emit('click', e);
            }
          "
          variant="none"
          ref="reference"
          class="group inline-flex flex-wrap items-center gap-x-1 text-left"
          data-test="timepicker_button"
        >
          <ClockIcon size="14" />
          <span class="uppercase" data-test="timepicker_button_text">{{
            displayText
          }}</span>
          <!-- <SmoothText :text="displayText" class="uppercase" /> -->
        </ButtonComponent>
      </template>

      <div
        class="min-w-fit overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-black dark:ring-neutral-700"
      >
        <div v-if="typeof cursor != 'object'">
          <span style="display: none">{{ now }}</span>
          <PopoverButton
            @click="
              value = interpretTime({
                time: moment().utc().format('HH:mm:00'),
              })
            "
            class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
            data-test="time_picker_set_now"
          >
            <span style="font-weight: bold"> Now </span>
            <span style="color: var(--color-medium-gray)">
              {{ interpretTime({ time: moment().format("HH:mm") }) }}
            </span>
          </PopoverButton>
          <PopoverButton
            @click="
              value = interpretTime({
                time: moment().utc().add(1, 'hour').format('HH:mm:00'),
              })
            "
            class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
            data-test="time_picker_set_in_1_hour"
          >
            <span style="font-weight: bold"> In 1 Hour </span>
            <span style="color: var(--color-medium-gray)">
              {{
                interpretTime({
                  time: moment().add(1, "hour").format("HH:mm"),
                })
              }}
            </span>
          </PopoverButton>
          <!-- <button
        @click.prevent="
          (edit = false),
            (value = interpretTime({
              time: moment().utc().add(3, 'hour').format('HH:mm:00'),
            }))
        "
        style="display: flex; justify-content: space-between"
      >
        <span style="font-weight: bold"> In 3 Hours </span>
        <span style="color: var(--color-medium-gray)">
          {{ interpretTime({ time: moment().add(3, "hour").format("HH:mm") }) }}
        </span>
      </button>-->
          <div
            class="px-3 py-1"
            style="display: flex; gap: 1.5rem; justify-content: space-between"
          >
            <!-- Hours: Start-->
            <div style="display: flex; gap: 0.5rem">
              <ButtonComponent
                @click.prevent="
                  cursor = moment(date + ' ' + cursor)
                    .add(-1, 'hours')
                    .format('HH:mm:ss')
                "
              >
                &minus;
              </ButtonComponent>

              <span
                style="
                  font-weight: bold;
                  min-width: 1.75rem;
                  text-align: center;
                "
              >
                {{
                  moment
                    .utc(date + " " + cursor)
                    .local()
                    .format("HH")
                }}
              </span>
              <ButtonComponent
                @click.prevent="
                  cursor = moment(date + ' ' + cursor)
                    .add(1, 'hours')
                    .format('HH:mm:ss')
                "
              >
                &plus;
              </ButtonComponent>
            </div>
            <!-- Hours: End -->
            <!-- Minutes: Start-->
            <div
              style="
                display: flex;
                gap: 0.5rem;
                color: var(--color-medium-dark-gray);
              "
            >
              <ButtonComponent
                @click.prevent="
                  cursor = moment(date + ' ' + cursor)
                    .add(-5, 'minutes')
                    .format('HH:mm:ss')
                "
              >
                &minus;
              </ButtonComponent>

              <span
                style="
                  font-weight: bold;
                  min-width: 1.75rem;
                  text-align: center;
                "
              >
                {{
                  moment
                    .utc(date + " " + cursor)
                    .local()
                    .format("mm")
                }}
              </span>
              <ButtonComponent
                @click.prevent="
                  value = moment(date + ' ' + cursor)
                    .add(5, 'minutes')
                    .format('HH:mm:ss')
                "
              >
                &plus;
              </ButtonComponent>
            </div>
            <!-- Minutes: End -->
            <!-- Seconds: Start-->
            <div
              style="
                display: none;
                gap: 0.5rem;
                color: var(--color-medium-gray);
              "
            >
              <ButtonComponent
                @click.prevent="
                  cursor = moment(date + ' ' + cursor)
                    .add(-15, 'seconds')
                    .format('HH:mm:ss')
                "
              >
                &minus;
              </ButtonComponent>

              <span
                style="
                  font-weight: bold;
                  min-width: 1.75rem;
                  text-align: center;
                "
              >
                {{
                  moment
                    .utc(date + " " + cursor)
                    .local()
                    .format("ss")
                }}
              </span>
              <ButtonComponent
                @click.prevent="
                  cursor = moment(date + ' ' + cursor)
                    .add(15, 'seconds')
                    .format('HH:mm:ss')
                "
              >
                &plus;
              </ButtonComponent>
            </div>
            <!-- Seconds: End -->
          </div>
          <div v-if="cursor != value">
            <PopoverButton
              @click="value = cursor"
              class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
            >
              Save
            </PopoverButton>
          </div>

          <div
            v-if="otherentries"
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-height: 100px;
              overflow-y: auto;
              scrollbar-gutter: stable;
            "
            data-test="time_picker_duration"
          >
            <div
              v-for="(otherentry, index) in otherentries?.filter(
                (e) => e.schedule?.time,
              )"
              :key="index"
              style="
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                align-items: center;
              "
            >
              <ButtonComponent
                style="
                  background: var(--color-dark-gray);
                  padding: 0.25rem 0.5rem;
                  border-radius: 4px;
                  color: var(--color-white);
                "
                @click="
                  (value = moment
                    .utc(
                      otherentry.schedule.date + ' ' + otherentry.schedule.time,
                    )
                    .add(-entry.schedule.duration, 'seconds')
                    .format('HH:mm:ss')),
                    (edit = false)
                "
                :title="
                  moment
                    .utc(
                      otherentry.schedule.date + ' ' + otherentry.schedule.time,
                    )
                    .add(-entry.schedule.duration, 'seconds')
                    .local()
                    .format('HH:mm:ss')
                "
              >
                &minus;
              </ButtonComponent>
              <span
                style="
                  flex-grow: 1;
                  font-size: var(--font-size-smaller);
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  max-width: 8rem;
                  overflow: hidden;
                  display: inline-block;
                "
                >{{ otherentry.name }}</span
              >
              <ButtonComponent
                style="
                  background: var(--color-dark-gray);
                  padding: 0.25rem 0.5rem;
                  border-radius: 4px;
                  color: var(--color-white);
                "
                @click="
                  (value = moment
                    .utc(
                      otherentry.schedule.date + ' ' + otherentry.schedule.time,
                    )
                    .add(otherentry.schedule.duration, 'seconds')
                    .format('HH:mm:ss')),
                    (edit = false)
                "
                :title="
                  moment
                    .utc(
                      otherentry.schedule.date + ' ' + otherentry.schedule.time,
                    )
                    .add(otherentry.schedule.duration, 'seconds')
                    .local()
                    .format('HH:mm:ss')
                "
              >
                &plus;
              </ButtonComponent>
            </div>
          </div>

          <PopoverButton
            @click="value = null"
            class="flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
            data-test="time_picker_unset"
          >
            <span> Unset </span>
            <TrashIcon class="h-5 w-5" />
          </PopoverButton>
          <small class="px-3 py-1" style="display: block; text-align: center">
            Duration
          </small>
          <div
            class="px-3 py-1"
            style="
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              max-height: 100px;
              overflow-y: auto;
              scrollbar-gutter: stable;
            "
            data-test="time_picker_duration"
          >
            <PopoverButton
              v-for="i in 12"
              :key="i"
              @click="
                (edit = false),
                  (duration = 60 * (5 * i)),
                  $emit('update:duration', duration)
              "
              style="display: flex; justify-content: space-between"
              :data-test="'time_picker_duration_' + i * 5 + '_minutes'"
            >
              <span style="font-weight: bold"> {{ 5 * i }} Min. </span>
              <span v-if="value" style="color: var(--color-medium-gray)">
                &rarr;
                {{
                  valToHtml(value, "HH:mm", {
                    key: "seconds",
                    val: 5 * i * 60,
                  })
                }}
              </span>
            </PopoverButton>
            <PopoverButton
              v-for="i in 28"
              :key="i"
              @click="
                (edit = false),
                  (duration = 60 * 60 + 60 * (15 * i)),
                  $emit('update:duration', duration)
              "
              style="display: flex; justify-content: space-between"
            >
              <span style="font-weight: bold"> {{ 1 + i / 4 }} Hrs. </span>
              <span v-if="value" style="color: var(--color-medium-gray)">
                &rarr;
                {{
                  valToHtml(value, "HH:mm", {
                    key: "seconds",
                    val: 60 * 60 + 15 * i * 60,
                  })
                }}
              </span>
            </PopoverButton>
          </div>

          <PopoverButton
            v-if="duration"
            @click="
              (edit = false),
                (duration = null),
                $emit('update:duration', duration)
            "
            style="display: flex; justify-content: space-between"
          >
            <span style="font-weight: bold"> Unset </span>
            <span style="color: var(--color-medium-gray)"> </span>
          </PopoverButton>
        </div>

        <div v-if="typeof cursor == 'object'">
          <div class="justfy-between flex gap-4 px-4 py-3">
            <span
              v-if="value?.op"
              :style="{
                color:
                  value.op == 'now' ||
                  (value.op.slice(0, 4) == 'next' &&
                    (typeof value.x == 'undefined' || value.x >= 0)) ||
                  (value.op.slice(0, 4) == 'last' &&
                    (typeof value.x == 'undefined' || value.x <= 0))
                    ? 'var(--color-dark-gray)'
                    : 'var(--color-medium-gray)',
              }"
              style="
                font-size: 82%;
                font-weight: bold;
                text-transform: uppercase;
              "
            >
              in
              <!--
          :title="
            value.op == 'tomorrow' ||
            (value.op.slice(0, 4) == 'next' &&
              (typeof value.x == 'undefined' || value.x > 0)) ||
            (value.op.slice(0, 4) == 'last' &&
              (typeof value.x == 'undefined' || value.x < 0))
              ? 'this affix is active - decrease the number into the negative to deactivate'
              : 'this affix is inactive - increase the number into the positive to activate'
          "-->
            </span>
            <span>
              <span style="display: flex; gap: 0.5rem">
                <ButtonComponent
                  @click.prevent="modifyRelativeDate(-1, value)"
                  data-test="timepicker_relative_number_minus"
                >
                  &minus;
                </ButtonComponent>
                <span style="font-weight: bold">{{
                  relativeTimespan(value)
                    ? Math.abs(relativeTimespan(value)[0])
                    : null
                }}</span>
                <ButtonComponent
                  @click.prevent="modifyRelativeDate(1, value)"
                  data-test="timepicker_relative_number_plus"
                >
                  &plus;
                </ButtonComponent>
              </span>
            </span>
            <span>
              <span style="display: flex; gap: 0.5rem">
                <ButtonComponent
                  @click.prevent="modifyRelativeTimespan(-1, value)"
                >
                  &minus;
                </ButtonComponent>
                <span
                  style="
                    font-weight: bold;
                    min-width: 5rem;
                    display: inline-block;
                    text-align: center;
                    text-transform: uppercase;
                  "
                  >{{
                    relativeTimespan(value) ? relativeTimespan(value)[1] : null
                  }}</span
                >
                <ButtonComponent
                  @click.prevent="modifyRelativeTimespan(1, value)"
                >
                  &plus;
                </ButtonComponent>
              </span>
            </span>

            <span
              v-if="value?.op"
              :style="{
                color:
                  (value.op.slice(0, 4) == 'next' &&
                    (typeof value.x == 'undefined' || value.x < 0)) ||
                  (value.op.slice(0, 4) == 'last' &&
                    (typeof value.x == 'undefined' || value.x > 0))
                    ? 'var(--color-dark-gray)'
                    : 'var(--color-medium-gray)',
              }"
              style="
                font-size: 82%;
                font-weight: bold;
                text-transform: uppercase;
              "
            >
              ago
              <!--
          :title="
            value.op == 'yesterday' ||
            (value.op.slice(0, 4) == 'next' &&
              (typeof value.x == 'undefined' || value.x < 0)) ||
            (value.op.slice(0, 4) == 'last' &&
              (typeof value.x == 'undefined' || value.x > 0))
              ? 'this affix is active - increase the number into the positive to deactivate'
              : 'this affix is inactive - decrease the number into the negative to activate'
          "-->
            </span>
          </div>
          <!-- Start : Unset Schedule Button -->
          <PopoverButton
            @click="[(value = entry ? { op: 'null' } : { op: 'unset' })]"
            class="flex w-full cursor-pointer items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
          >
            <XMarkIcon class="h-4 w-4" />
            Unset
          </PopoverButton>
          <!-- End : Unset Schedule Button -->
        </div>
      </div>
    </PopoverHelper>
  </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  PopoverOverlay,
} from "@headlessui/vue";

import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);
const { floatingStyles } = useFloating(reference, floating, {
  placement: "top-start",
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>

<script>
import moment from "moment";
import { Scheduling } from "../mixins/Scheduling";
import { EditUtilities } from "../mixins/EditUtilities";
// eslint-disable-next-line no-unused-vars
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { ClockIcon } from "lucide-vue-next";
export default {
  components: { ClockIcon },
  mixins: [Scheduling, EditUtilities],
  props: {
    entry: Object,
    otherentries: Array,
    teleport: Boolean,
  },
  data() {
    return {
      moment: moment,
      now: null,
      cursor:
        typeof this.modelValue == "object"
          ? JSON.parse(JSON.stringify(this.modelValue))
          : this.modelValue,
    };
  },
  watch: {
    "entry.schedule.time": function (n) {
      this.value = n;
      this.cursor = n;
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.now = moment.utc();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  computed: {
    duration: {
      get() {
        return this.entry?.schedule?.duration;
      },
      set(value) {
        this.$emit("update:duration", value);
      },
    },
    displayText() {
      if (this.value && this.duration)
        return (
          this.valToHtml(this.value) +
          " - " +
          this.valToHtml(this.value, "HH:mm", {
            key: "seconds",
            val: this.duration,
          })
        );
      if (!this.value && this.duration) return this.duration / 60 + " min.";
      if (this.value || !this.duration) return this.valToHtml(this.value);
      return "Set Time";
    },
    date() {
      return moment().format("YYYY-MM-DD");
    },
  },
  methods: {
    timePart(part, time) {
      if (time == null) return "--";
      switch (part) {
        case "H":
          return moment
            .utc(this.date + " " + time)
            .local()
            .format("HH");
        case "M":
          return moment
            .utc(this.date + " " + time)
            .local()
            .format("mm");
        case "S":
          return moment
            .utc(this.date + " " + time)
            .local()
            .format("ss");
      }
    },
    modifyRelativeDate(dir, time) {
      switch (dir) {
        case 1:
          switch (time.op) {
            case "now":
              time.op = "nextXHours";
              time.x = 1;
              break;
            default:
              time.x =
                typeof time.x != "undefined"
                  ? time.op.slice(0, 4) == "next"
                    ? time.x + 1
                    : time.x - 1
                  : 0;
              if (time.x < 0) {
                time.op =
                  time.op.slice(0, 4) == "next"
                    ? "last" + time.op.slice(4)
                    : "next" + time.op.slice(4);
                time.x = 1;
              }
          }
          break;
        case -1:
          switch (time.op) {
            case "now":
              time.op = "lastXHours";
              time.x = 1;
              break;
            default:
              time.x =
                typeof time.x != "undefined"
                  ? time.op.slice(0, 4) == "next"
                    ? time.x - 1
                    : time.x + 1
                  : 0;
              if (time.x < 0) {
                time.op =
                  time.op.slice(0, 4) == "next"
                    ? "last" + time.op.slice(4)
                    : "next" + time.op.slice(4);
                time.x = 1;
              }
          }
          break;
      }
      if ((time.op == "nextXHours" || time.op == "lastXHours") && time.x == 0) {
        delete time.x;
        time.op = "now";
      }
      if (time.op == "nextXHours" && time.x >= 24) {
        time.x = 0;
      }
      if (time.op == "lastXHours" && time.x >= 24) {
        time.x = 0;
      }
      if (time.op == "nextXMinutes" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "lastXMinutes" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "nextXSeconds" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "lastXSeconds" && time.x >= 60) {
        time.x = 0;
      }
      //this.$emit("update", this.value);
    },
    modifyRelativeTimespan(dir, time) {
      if (time !== null) {
        switch (dir) {
          case 1:
            switch (time.op) {
              case "nextXSeconds":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "nextXMinutes":
                time.op = "nextXHours";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXSeconds":
                time.op = "lastXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXMinutes":
                time.op = "lastXHours";
                if (typeof time.x == "undefined") time.x = 1;
                break;
            }
            break;
          case -1:
            switch (time.op) {
              case "now":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 0;
                break;
              case "nextXMinutes":
                time.op = "nextXSeconds";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "nextXHours":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXMinutes":
                time.op = "lastXSeconds";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXHours":
                time.op = "lastXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
            }
            break;
        }
        if (
          (time.op == "nextXHours" || time.op == "lastXHours") &&
          time.x == 0
        ) {
          delete time.x;
          time.op = "now";
        }
      }
      //this.$emit("update", this.value);
    },
    relativeTimespan(time) {
      if (time === null) {
        return [0, "hours"];
      }
      switch (time.op) {
        case "now":
          return [0, "hours"];
        case "lastXHours":
        case "nextXHours":
          return [time.x, "hour" + (time.x != 1 ? "s" : "")];
        case "lastXMinutes":
        case "nextXMinutes":
          return [time.x, "minute" + (time.x != 1 ? "s" : "")];
        case "lastXSeconds":
        case "nextXSeconds":
          return [time.x, "second" + (time.x != 1 ? "s" : "")];
      }
    },
    valToHtml(val, format = "HH:mm", mod) {
      if (val) {
        if (val.op == "null") return "unset time";
        if (val.op == "now") {
          return "now";
        }
        if (val.op == "nextXHours") {
          if (val.x == 0) return "now";
          return "in " + val.x + " hour" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXMinutes") {
          if (val.x == 0) return "now";
          return "in " + val.x + " minute" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXSeconds") {
          if (val.x == 0) return "now";
          return "in " + val.x + " second" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "lastXHours") {
          if (val.x == 0) return "now";
          return val.x + " hour" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXMinutes") {
          if (val.x == 0) return "now";
          return val.x + " minute" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXSeconds") {
          if (val.x == 0) return "now";
          return val.x + " second" + (val.x != 1 ? "s" : "") + " ago";
        }
        /*
                @note this shows in completedAt Component: "TODAY now" - I don't like that.
                if (format == "HH:mm" && val == moment().utc().format("HH:mm:ss")) {
                  return "now";
                }*/
        var datetime = moment.utc(moment.utc().format("YYYY-MM-DD " + val));
        if (datetime.format("ss") != "00") format = format + ":ss";
        if (mod) {
          datetime = datetime.add(mod.val, mod.key);
        }
        return datetime.local().format(format);
      }
      return;
      // return "--:--";
    },
  },
};
</script>
