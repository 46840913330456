<template>
  <div class="flex flex-col">
    <draggable
      class="list-group"
      v-model="statuses"
      group="statuses"
      handle="[data-handle]"
      @change="updatePositions"
      itemKey="name"
    >
      <template #item="{ element }">
        <div class="mb-1 flex flex-wrap items-start gap-2">
          <a
            data-handle
            class="flex cursor-move gap-1 self-center"
            data-test="status_position_handle"
          >
            <ChevronUpDownIcon class="h-5 w-5 text-gray-900" />
          </a>
          <ColorPicker
            v-model="element.color"
            @update="
              (color) => {
                element.color = color;
                updateStatus(element);
              }
            "
            :hideLabel="true"
            class="flex-1"
          />

          <TextInput
            v-model="element.name"
            @update="updateStatus(element)"
            :class="$style.textInput"
          />
          <StatusTypePicker
            v-model="element.type"
            @update:modelValue="
              (type) => {
                element.type = type;
                updateStatus(element);
              }
            "
            :options="['open', 'custom', 'done']"
            color="secondary"
          />
          <button
            @click="deleteStatus(element)"
            type="button"
            class="rounded-md bg-white px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-black dark:text-neutral-100 dark:hover:bg-neutral-900"
            :data-test="'delete_status_' + $slugify(element.name)"
          >
            <TrashIcon class="h-4 w-4" />
          </button>
        </div>
      </template>
    </draggable>

    <div class="flex flex-wrap items-start gap-2" data-test="status_new_status">
      <a
        data-handle
        class="pointer-events-none flex cursor-move gap-1 self-center opacity-0"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-900" />
      </a>
      <ColorPicker
        v-model="newStatus.color"
        @update="(color) => (newStatus.color = color)"
        :hideLabel="true"
        class="flex-1"
        data-test="status_new_status_color"
      />

      <TextInput
        v-model="newStatus.name"
        :class="$style.textInput"
        placeholder="Add Status"
        data-test="status_new_status_name"
      />

      <StatusTypePicker
        v-model="newStatus.type"
        :options="['open', 'custom', 'done']"
        color="secondary"
        data-test="status_new_status_type"
      />
      <button
        @click="addStatus"
        type="button"
        class="rounded-md bg-white px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-black dark:text-neutral-100 dark:hover:bg-neutral-900"
        data-test="status_new_status_add"
      >
        <PlusIcon class="h-4 w-4" />
      </button>
    </div>
  </div>
</template>

<script>
import {
  ChevronUpDownIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/vue/24/outline";
import ColorPicker from "../helpers/ColorPicker.vue";
import TextInput from "../helpers/TextInput.vue";
import { statusBlueprint } from "@/addonBlueprint";
import StatusTypePicker from "../builders/StatusTypePicker.vue";

import draggable from "vuedraggable";

export default {
  props: {
    modelValue: Object,
    entry: Object,
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    statuses: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  components: {
    ColorPicker,
    TextInput,
    TrashIcon,
    PlusIcon,
    StatusTypePicker,
    draggable,
    ChevronUpDownIcon,
  },
  data() {
    return {
      newStatus: statusBlueprint(this.entry, this),
    };
  },
  methods: {
    updatePositions: function () {
      this.statuses = this.statuses.map((s, i) => {
        s.position = i;
        return s;
      });
    },
    addStatus() {
      // Set position of new status
      this.newStatus.position = this.statuses.length;
      this.statuses = [...this.statuses, this.newStatus];

      //this.space.statuses.push(this.newStatus);
      this.newStatus = statusBlueprint(this.entry, this);
    },
    updateStatus(status) {
      this.$store.dispatch("push", {
        event: "status_update",
        params: { status: status },
        entry: this.entry,
      });

      this.space.statuses = this.space.statuses.map((s) => {
        if (s.id === status.id) {
          return status;
        }
        return s;
      });
    },
    deleteStatus(status) {
      this.$store.dispatch("push", {
        event: "status_delete",
        params: { id: status.id },
        entry: this.entry,
      });

      // Find all entries with that status and remove
      this.$store.getters.entries
        .filter((e) => e.status_id === status.id)
        .forEach((e) => {
          e.status_id = null;
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: e },
            entry: e,
          });
        });

      // Remove status from global space statuses list
      // this.space.statuses = this.space.statuses.filter(
      //   (s) => s.id !== status.id,
      // );
      // Remove status from entry statuses list for modelValue:update
      this.statuses = this.statuses.filter((s) => s.id !== status.id);

      this.$nextTick(() => {
        this.updatePositions();
      });
    },
  },
};
</script>

<style module lang="scss">
.textInput {
  @apply flex-1 rounded-md px-2.5 py-1.5 text-sm shadow-sm;

  background-color: var(--ps-base-background-color);
  border: 1px solid var(--ps-base-border-color);
  color: var(--ps-base-text-color);

  @media (hover: hover) {
    &:hover {
      background-color: var(--ps-base-tinted-color);
    }
  }
}
</style>
