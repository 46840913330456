<template>
  <PopoverHelper>
    <template #button>
      <div v-bind="$attrs" placeholder="Set Date">
        {{
          $moment(date).isValid() ? $moment(date).format("DD.MM.YYYY") : null
        }}
      </div>
    </template>
    <template v-slot="{ close }">
      <CalendarComponent
        v-slot="{ date: _date } = slotProps"
        :class="$style.calendar"
      >
        <button
          @click.prevent="(date = _date), close()"
          :class="{
            'font-bold text-black': _date == $moment().format('YYYY-MM-DD'),

            'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white':
              _date == date,
          }"
          :data-test="'date_picker_set_' + _date"
        >
          {{ $moment(_date).format("DD") }}
        </button>
      </CalendarComponent>

      <div :class="$style.utilitiesRow">
        <button
          @click="(date = null), close()"
          :class="$style.buttonDelete"
          data-test="date_picker_clear"
        >
          <TrashIcon size="18" />
        </button>
      </div>
    </template>
  </PopoverHelper>
</template>

<script>
import { TrashIcon } from "lucide-vue-next";
export default {
  inheritAttrs: false,
  components: {
    TrashIcon,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    date: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>

<style module lang="scss">
.button {
  composes: input from "@/styles/form.module.scss";
  font-variant-numeric: tabular-nums;
}
.calendar {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.utilitiesRow {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.buttonDelete {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonRemove from "@/styles/buttons.module.scss";
}
</style>
