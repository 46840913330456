<template>
  <div class="relative">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-neutral-300 dark:border-neutral-700" />
    </div>
    <div class="relative flex items-center justify-between">
      <span
        class="flex items-center gap-1.5 bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
      >
        <slot name="title" />
      </span>
      <div
        v-if="url"
        class="flex gap-2 bg-white pl-2 dark:bg-black dark:text-neutral-500"
      >
        <a :href="url" target="_blank">
          <QuestionMarkCircleIcon class="h-5 w-5" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: null,
    },
  },
};
</script>
