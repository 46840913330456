<template>
  <div :class="$style.timeTrackingStarter">
    <!-- {{ internalTimeTracking }} -->
    <!-- {{ customDurationAsSeconds }} -->
    <div ref="reference" :class="$style.firstRow">
      <div :class="$style.currentDuration">
        <TransitionGroupHelper
          :enter="{ translateX: [50, 0], opacity: [0, 1] }"
          :leave="{ translateX: [0, -50], opacity: [1, 0] }"
        >
          <input
            ref="input"
            v-if="!userHasInEntryActiveTimeTracking"
            type="text"
            v-model="durationAsText"
            placeholder="Enter time or start the timer"
            @focus="
              () => {
                $refs.input.select();
                showPopover = true;
              }
            "
            @blur="handleOnBlur"
            @input="handleCustomDurationInput"
            @keydown.enter.stop.prevent="
              () => {
                $refs.input.blur();
                handleSaveTimeTracking();
                showPopover = false;
                $emit('close');
              }
            "
            data-test="time_tracking_settings_duration_input"
          />
          <div v-else class="text-center">
            {{
              getDurationOfTimeTracking(activeTimeTrackingByUser).format(
                "hh:mm:ss",
                {
                  stopTrim: "m",
                },
              )
            }}
          </div>
        </TransitionGroupHelper>
      </div>
      <div
        ref="floating"
        v-if="
          showPopover &&
          !userHasInEntryActiveTimeTracking &&
          humanReadableDuration
        "
        @click="customDuration = humanReadableDuration"
        :class="$style.popover"
        :style="floatingStyles"
      >
        {{ humanReadableDuration }}
      </div>
      <div
        v-if="!time_tracking?.id"
        class="relative inline-block h-[30px] w-[30px]"
      >
        <TransitionGroupHelper
          :enter="{ translateY: [50, 0], opacity: [0, 1] }"
          :leave="{
            translateY: [0, -50],
            opacity: [1, 0],
            begin: (anim) => {
              anim.animatables[0].target.style.position = 'absolute';
            },
          }"
        >
          <button
            v-if="customDurationAsSeconds"
            @click="handleSaveTimeTracking"
            :class="$style.startButton"
            data-test="time_tracking_settings_action_save"
          >
            <SaveIcon size="20" :strokeWidth="2.5" />
          </button>

          <button
            v-else-if="!userHasInEntryActiveTimeTracking"
            @click="startTimeTracking(entry)"
            :class="$style.startButton"
            data-test="time_tracking_settings_action_start"
          >
            <PlayIcon size="20" :strokeWidth="2.5" />
          </button>

          <button
            v-else
            @click="stopTimeTracking(entry)"
            :class="$style.startButton"
            data-test="time_tracking_settings_action_stop"
          >
            <PauseIcon size="20" :strokeWidth="2.5" />
          </button>
        </TransitionGroupHelper>
      </div>
    </div>

    <div
      v-if="internalTimeTracking && !userHasInEntryActiveTimeTracking"
      :class="$style.secondRow"
    >
      <fieldset>
        <CalendarCogIcon size="16" />
        <DatePicker v-model="customDate" :class="$style.datePicker" />
        <TimePicker
          v-model="timeStart"
          :class="$style.timePicker"
          data-test="time_tracking_settings_time_start"
        />
        -
        <DurationPicker
          v-model="duration"
          :basis="internalTimeTracking?.start_at"
          :class="$style.durationPicker"
          data-test="time_tracking_settings_time_end"
        >
          <!-- <template v-slot:timeSuggestion="{ timeLocal }">
            <button
              v-show="
                $moment
                  .utc($moment().format('YYYY-MM-DD') + ' ' + timeLocal)
                  .isAfter(
                    $moment.utc(
                      $moment().format('YYYY-MM-DD') + ' ' + timeStart,
                    ),
                  )
              "
              :class="$style.buttonTimeSuggestion"
            >
              <span>
                {{
                  $moment
                    .utc($moment().format("YYYY-MM-DD") + " " + timeLocal)
                    .local()
                    .format("HH:mm")
                }}
              </span>
              <span class="ml-auto text-neutral-500">
                {{
                  $moment
                    .utc(
                      $moment(timeLocal, "HH:mm").diff(
                        $moment(timeStart, "HH:mm"),
                      ),
                    )
                    .format("H[h] mm[m]", {
                      stopTrim: "m",
                    })
                }}
              </span>
            </button>
          </template> -->
        </DurationPicker>
      </fieldset>
      <fieldset v-if="time_tracking?.id" class="justify-end">
        <button
          @click="
            () => {
              deleteTimeTracking(time_tracking);
              $emit('close');
            }
          "
          :class="$style.buttonDelete"
          data-test="time_tracking_settings_action_delete"
        >
          <TrashIcon size="16" />
        </button>
        <button
          @click="
            () => {
              handleSaveTimeTracking();
              $emit('close');
            }
          "
          :class="$style.buttonSave"
          data-test="time_tracking_settings_action_save"
        >
          <SaveIcon size="16" />
          Save
        </button>
      </fieldset>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  transform: false,
  placement: "bottom-start",
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
<script>
import { TimeTrackingsMixin } from "@/components/timetrackings/mixins/TimeTrackingsMixin";
import { Tracking } from "@/components/mixins/Tracking";
import {
  PlayIcon,
  PauseIcon,
  CalendarCogIcon,
  SaveIcon,
  TrashIcon,
} from "lucide-vue-next";

import DatePicker from "@/components/forms/partials/DatePicker.vue";
import TimePicker from "@/components/forms/partials/TimePicker.vue";
import DurationPicker from "@/components/forms/partials/DurationPicker.vue";

import { addonBlueprint } from "@/addonBlueprint";

export default {
  components: {
    SaveIcon,
    PlayIcon,
    PauseIcon,
    TrashIcon,
    CalendarCogIcon,
    DatePicker,
    TimePicker,
    DurationPicker,
  },
  mixins: [TimeTrackingsMixin, Tracking],
  props: {
    modelValue: Object,
    entry: Object,
  },
  mounted() {
    if (this.time_tracking) {
      this.internalTimeTracking = this.time_tracking;

      const startTime = this.$moment.utc(this.internalTimeTracking.start_at);
      const endTime = this.$moment.utc(this.internalTimeTracking.end_at);
      const d = this.$moment.duration(endTime.diff(startTime));

      this.durationAsText = d.format("H[h] m[m] s[s]", {
        stopTrim: "m",
      });
      this.interpretDuration(this.durationAsText + "s");
    } else {
      this.internalTimeTracking = addonBlueprint(
        "time_track",
        this.entry,
        this,
      );
      this.internalTimeTracking.start_at = this.$moment
        .utc()
        .format("YYYY-MM-DD HH:mm:00");
      this.internalTimeTracking.end_at = this.$moment
        .utc()
        .format("YYYY-MM-DD HH:mm:00");
    }
  },
  computed: {
    time_tracking: {
      get() {
        return this.modelValue;
      },
      set(value) {
        // console.log("set time_tracking", value, this.entry.name);

        const index = this.entry.time_trackings.findIndex(
          (tt) => tt.id === value.id,
        );

        // eslint-disable-next-line vue/no-mutating-props
        this.entry.time_trackings[index] = value;
        if (!this.entry.temp)
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
            undo: 1,
          });
      },
    },
    duration: {
      get() {
        if (this.internalTimeTracking) {
          const startTime = this.$moment.utc(
            this.internalTimeTracking.start_at,
          );
          const endTime = this.$moment.utc(this.internalTimeTracking.end_at);
          return this.$moment.duration(endTime.diff(startTime)).asSeconds();
        }
        return 0;
      },
      // eslint-disable-next-line no-unused-vars
      set(value) {
        // console.log("TimeTrackingSettings set duration", value);

        this.durationAsText = this.$moment
          .duration(value, "seconds")
          .format("H[h] m[m] s[s]", {
            stopTrim: "m",
          });

        this.timeEnd = this.$moment
          .utc(this.internalTimeTracking.start_at)
          .add(value, "seconds")
          .format("HH:mm:ss");

        // this.$emit("update:modelValue", value);
      },
    },
    timeStart: {
      get() {
        if (this.internalTimeTracking)
          return this.$moment(this.internalTimeTracking.start_at).format(
            "HH:mm:ss",
          );
        return this.$moment.utc().format("HH:mm:ss");
      },
      set(value) {
        const timeStartMomentUTC = this.$moment(this.customDate + " " + value);

        if (timeStartMomentUTC.isValid()) {
          this.internalTimeTracking = {
            ...this.internalTimeTracking,
            ...{
              start_at: timeStartMomentUTC.format("YYYY-MM-DD HH:mm:ss"),
              end_at: timeStartMomentUTC
                .add(this.duration, "seconds")
                .format("YYYY-MM-DD HH:mm:ss"),
            },
          };

          this.duration = Math.abs(
            this.$moment(this.internalTimeTracking.end_at).diff(
              this.$moment(this.internalTimeTracking.start_at),
              "seconds",
            ),
          );
        }
      },
    },
    timeEnd: {
      get() {
        if (this.internalTimeTracking)
          return this.$moment
            .utc(this.internalTimeTracking.end_at)
            .format("HH:mm");
        return this.$moment().utc().format("HH:mm");
      },
      set(value) {
        const timeEndMomentUTC = this.$moment(this.customDate + " " + value);
        if (timeEndMomentUTC.isValid()) {
          this.internalTimeTracking = {
            ...this.internalTimeTracking,
            ...{
              end_at: timeEndMomentUTC.format("YYYY-MM-DD HH:mm:ss"),
            },
          };

          this.customDurationAsSeconds = Math.abs(
            this.$moment(this.internalTimeTracking.end_at).diff(
              this.$moment(this.internalTimeTracking.start_at),
              "seconds",
            ),
          );
        }
      },
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    // customDurationAsSeconds(n, o) {
    //   // console.log("customDurationAsSeconds " + o + " -> " + n);
    //   this.timeStart = this.$moment
    //     .utc(this.customDate + " " + this.timeEnd)
    //     .add(-this.customDurationAsSeconds, "seconds")
    //     .format("HH:mm:ss");
    // },
    // customTimeStart() {
    //   const customTimeStartMoment = this.$moment(
    //     this.customDate + " " + this.customTimeStart,
    //   );
    //   if (customTimeStartMoment.isValid()) {
    //     this.customTimeEnd = this.$moment(
    //       this.customDate + " " + this.customTimeStart,
    //     )
    //       .add(this.customDurationAsSeconds, "seconds")
    //       .format("HH:mm");
    //     // console.log("customTimeStart", this.customTimeStart);
    //     // console.log("customTimeEnd", this.customTimeEnd);
    //   }
    // },
    // customTimeEnd() {
    //   const customTimeEndMoment = this.$moment(
    //     this.customDate + " " + this.customTimeEnd,
    //   );
    //   if (customTimeEndMoment.isValid()) {
    //     this.customDurationAsSeconds = Math.abs(
    //       this.$moment(this.customDate + " " + this.customTimeEnd).diff(
    //         this.$moment(this.customDate + " " + this.customTimeStart),
    //         "seconds",
    //       ),
    //     );
    //     this.customDuration = this.$moment
    //       .duration(this.customDurationAsSeconds, "seconds")
    //       .format("H[h] m[m] s[s]", {
    //         stopTrim: "m",
    //       });
    //   }
    // },
  },
  data() {
    return {
      showPopover: false,
      durationAsText: "",

      customDuration: "",
      customDurationAsSeconds: 0,
      humanReadableDuration: "",

      customDate: this.$moment().format("YYYY-MM-DD"),
      customTimeStart: this.$moment().format("HH:mm"),
      customTimeEnd: this.$moment().format("HH:mm"),

      internalTimeTracking: null,
    };
  },
  methods: {
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    },
    handleOnBlur() {
      if (this.duration != this.durationAsText) {
        const durationInSeconds = this.interpretDuration(this.durationAsText);
        if (!this.time_tracking) {
          this.internalTimeTracking = {
            ...this.internalTimeTracking,
            ...{
              start_at: this.$moment
                .utc(this.internalTimeTracking.end_at)
                .subtract(durationInSeconds, "seconds")
                .format("YYYY-MM-DD HH:mm:ss"),
            },
          };
        }
        this.duration = durationInSeconds;
      }
      if (this.showPopover) {
        this.delayShowPopover(false);
      }
    },
    handleCustomDurationInput(event) {
      const input = event.target.value.trim();

      if (input === "") {
        // this.customDurationAsSeconds = 0;
        this.humanReadableDuration = "";
        return;
      }

      this.interpretDuration(input);
    },
    interpretDuration(input) {
      let durationInSeconds = 0;
      let humanReadableDuration = "";
      let lastUnit = null;

      // Regular expressions for matching different variations
      const hourRegex = /^(h|ho|hour|hours)$/i;
      const minuteRegex = /^(m|min|minute|minutes)$/i;
      const secondRegex = /^(s|sec|second|seconds)$/i;

      // Split the input into parts that include both numbers and units
      const parts = input.match(/(\d+|\D+)/g);

      // Process each part
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i].trim();
        if (!isNaN(part) && part !== "") {
          // If the part is a number, check the next part for the unit
          const nextPart = parts[i + 1] ? parts[i + 1].trim() : null;
          if (nextPart && hourRegex.test(nextPart)) {
            const hours = parseInt(part);
            durationInSeconds += hours * 3600;
            humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
            lastUnit = "hours";
            i++; // Skip the next part as it is already processed
          } else if (nextPart && minuteRegex.test(nextPart)) {
            const minutes = parseInt(part);
            durationInSeconds += minutes * 60;
            humanReadableDuration += `${minutes} minute${
              minutes > 1 ? "s" : ""
            } `;
            lastUnit = "minutes";
            i++; // Skip the next part as it is already processed
          } else if (nextPart && secondRegex.test(nextPart)) {
            const seconds = parseInt(part);
            durationInSeconds += seconds;
            humanReadableDuration += `${seconds} second${
              seconds > 1 ? "s" : ""
            } `;
            lastUnit = "seconds";
            i++; // Skip the next part as it is already processed
          } else {
            // Handle partial matches for units
            if (nextPart && nextPart.toLowerCase().startsWith("h")) {
              const hours = parseInt(part);
              if (!isNaN(hours)) {
                durationInSeconds += hours * 3600;
                humanReadableDuration += `${hours} hour${
                  hours > 1 ? "s" : ""
                } `;
                lastUnit = "hours";
                i++; // Skip the next part as it is already processed
              }
            } else if (nextPart && nextPart.toLowerCase().startsWith("m")) {
              const minutes = parseInt(part);
              if (!isNaN(minutes)) {
                durationInSeconds += minutes * 60;
                humanReadableDuration += `${minutes} minute${
                  minutes > 1 ? "s" : ""
                } `;
                lastUnit = "minutes";
                i++; // Skip the next part as it is already processed
              }
            } else if (nextPart && nextPart.toLowerCase().startsWith("s")) {
              const seconds = parseInt(part);
              if (!isNaN(seconds)) {
                durationInSeconds += seconds;
                humanReadableDuration += `${seconds} second${
                  seconds > 1 ? "s" : ""
                } `;
                lastUnit = "seconds";
                i++; // Skip the next part as it is already processed
              }
            } else {
              // Default to the last unit if no unit is specified
              if (lastUnit === "hours") {
                const minutes = parseInt(part);
                if (!isNaN(minutes)) {
                  durationInSeconds += minutes * 60;
                  humanReadableDuration += `${minutes} minute${
                    minutes > 1 ? "s" : ""
                  } `;
                }
              } else if (lastUnit === "minutes") {
                const seconds = parseInt(part);
                if (!isNaN(seconds)) {
                  durationInSeconds += seconds;
                  humanReadableDuration += `${seconds} second${
                    seconds > 1 ? "s" : ""
                  } `;
                }
              } else {
                // Default to hours if no previous unit
                const hours = parseInt(part);
                if (!isNaN(hours)) {
                  durationInSeconds += hours * 3600;
                  humanReadableDuration += `${hours} hour${
                    hours > 1 ? "s" : ""
                  } `;
                }
              }
            }
          }
        } else if (hourRegex.test(part)) {
          const hours = parseInt(parts[i - 1]);
          if (!isNaN(hours)) {
            durationInSeconds += hours * 3600;
            humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
            lastUnit = "hours";
          }
        } else if (minuteRegex.test(part)) {
          const minutes = parseInt(parts[i - 1]);
          if (!isNaN(minutes)) {
            durationInSeconds += minutes * 60;
            humanReadableDuration += `${minutes} minute${
              minutes > 1 ? "s" : ""
            } `;
            lastUnit = "minutes";
          }
        } else if (secondRegex.test(part)) {
          const seconds = parseInt(parts[i - 1]);
          if (!isNaN(seconds)) {
            durationInSeconds += seconds;
            humanReadableDuration += `${seconds} second${
              seconds > 1 ? "s" : ""
            } `;
            lastUnit = "seconds";
          }
        }
      }
      this.humanReadableDuration = humanReadableDuration.trim();

      return durationInSeconds;
      // console.log("Duration in seconds", durationInSeconds);
      // console.log("Human readable duration", this.humanReadableDuration);
    },
    handleSaveTimeTracking() {
      this.saveTimeTracking(this.entry, this.internalTimeTracking);

      // reset internalTimeTracking if it is a new time tracking
      if (!this.time_tracking) {
        this.internalTimeTracking = addonBlueprint(
          "time_track",
          this.entry,
          this,
        );
        this.internalTimeTracking.start_at = this.$moment
          .utc()
          .format("YYYY-MM-DD HH:mm:00");
        this.internalTimeTracking.end_at = this.$moment
          .utc()
          .format("YYYY-MM-DD HH:mm:00");
      }

      this.customDuration = "";
      this.customDurationAsSeconds = 0;
      this.humanReadableDuration = "";
    },
  },
};
</script>

<style module lang="scss">
.timeTrackingStarter {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .firstRow {
    display: flex;
    align-items: center;
    gap: 8px;

    .currentDuration {
      flex: 1;
      width: 100%;
      border: 1px solid #e2e8f0;
      border-radius: 0.375rem;

      > * {
        font-size: var(--ps-font-size-sm);
        line-height: var(--ps-line-height-sm);
        border: none;
        background-color: transparent;
        padding: 0;
        width: 100%;
        padding: 0.25rem 0.5rem;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.secondRow {
  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  > fieldset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.datePicker,
.timePicker,
.durationPicker {
  composes: input from "@/styles/form.module.scss";
  font-variant-numeric: tabular-nums;
}

.timePicker,
.durationPicker {
  width: 56px;
  text-align: center;
  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
}

.popover {
  composes: panel from "@/styles/panels.module.scss";
  z-index: 10;
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
}

.startButton {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonPrimary from "@/styles/buttons.module.scss";
  border-radius: 100%;
  padding: 0.25rem;
}

.settings {
  flex-basis: 100%;
  outline: 1px solid red;
}

.buttonSave {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonPrimary from "@/styles/buttons.module.scss";
  composes: buttonFlex from "@/styles/buttons.module.scss";
  padding: 0.25rem 0.5rem;
}

.buttonDelete {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonRemove from "@/styles/buttons.module.scss";
}

.buttonTimeSuggestion {
  @apply flex items-center gap-1;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
}
</style>
