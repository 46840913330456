<template>
  <div>
    <Popover v-slot="{ open }" class="relative">
      <PopoverButton ref="reference" class="flex">
        <slot name="button" :open="open">
          <ButtonComponent :size="size" :variant="variant" :color="color">
            <IconHelper name="image" size="20" />
            <span v-if="variant != 'round'"> Cover </span>
          </ButtonComponent>
        </slot>
      </PopoverButton>

      <Teleport to="body">
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <PopoverPanel
            class="absolute z-[100000] mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl"
          >
            <div
              ref="floating"
              :style="floatingStyles"
              class="z-[100000] overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 dark:ring-neutral-700"
            >
              <div class="relative bg-white p-5 dark:bg-black">
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300"
                  >URL</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    class="block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-300 dark:ring-neutral-700 dark:focus:ring-white"
                    placeholder=""
                    @keyup.enter="(event) => event.target.blur()"
                    @blur="
                      (event) => {
                        if (event.target.value.length) {
                          // eslint-disable-next-line vue/no-mutating-props
                          entry.cover = {
                            ...cover,
                            ...{ url: event.target.value },
                          };
                          $store.dispatch('push', {
                            event: 'entry_update',
                            params: { entry: entry },
                            entry: entry,
                          });
                        }
                        event.target.value = '';
                      }
                    "
                  />
                </div>
                <p class="mt-2 text-sm text-neutral-500" id="email-description">
                  Works with any image from the web.
                </p>
                <button
                  v-if="cover?.url"
                  @click="
                    // eslint-disable-next-line vue/no-mutating-props
                    (entry.cover = null),
                      $store.dispatch('push', {
                        event: 'entry_update',
                        params: { entry: entry },
                        entry: entry,
                      })
                  "
                  type="button"
                  class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-black dark:text-neutral-300 dark:ring-neutral-700 dark:hover:bg-neutral-900"
                >
                  Remove
                </button>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Teleport>
    </Popover>
  </div>
</template>
<script setup>
import { PopoverButton } from "@headlessui/vue";

import { Popover, PopoverPanel } from "@headlessui/vue";

import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
<script>
import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        if (this.modelValue) this.cover = this.modelValue;
        else this.cover = addonBlueprint("cover", this.entry);
      },
    },
    entry: function () {
      if (this.modelValue) this.cover = this.modelValue;
      else this.cover = addonBlueprint("cover", this.entry);
    },
  },
  data() {
    return {
      cover: null,
    };
  },
  mounted() {
    if (this.modelValue) this.cover = this.modelValue;
    else this.cover = addonBlueprint("cover", this.entry);
  },
};
</script>
