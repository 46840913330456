<template>
  <div
    ref="shell"
    :class="{
      'overflow-hidden rounded-2xl shadow-lg ring-1 ring-neutral-100': focus,
      'bg-neutral-100 dark:bg-neutral-900': !space,
      'bg-white dark:bg-black': space,
    }"
    class="max-w-full transform-gpu dark:text-white"
    style="transition: background-color 1s ease"
  >
    <FocusWrapper />

    <ShareWrapper />

    <!-- <Teleport to="body">
      <div
        v-if="sidebarOpen"
        @click="sidebarOpen = false"
        class="fixed inset-0 bg-neutral-900/20"
      />
      <div
        :class="{ '-ml-72': !sidebarOpen }"
        class="fixed inset-y-0 z-20 flex w-72 max-w-full flex-col"
        style="transition: margin-left 0.3s"
      >
        <LeftSidebar
          ref="sidebar-mobile-wrapper"
          @onEntryClick="sidebarOpen = sidebarOpen"
          :outputId="'navigation_mobile'"
        />
      </div>
    </Teleport> -->

    <!-- <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-neutral-900/20" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  id="left-sidebar-close"
                  class="absolute left-full flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 rounded-full p-2.5 focus-visible:outline-none focus-visible:ring focus-visible:ring-neutral-900 dark:focus-visible:ring-neutral-100"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <LeftSidebar
                ref="sidebar-mobile-wrapper"
                @onEntryClick="sidebarOpen = sidebarOpen"
                :outputId="'navigation_mobile'"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot> -->
    <Teleport to="body">
      <div
        v-if="sidebarOpen"
        @click="(sidebarOpen = false), (cursorIsOnLeftEdge = false)"
        class="fixed inset-0 bg-neutral-900/20 md:hidden"
      />
      <!-- Static sidebar for desktop -->
      <Transition @enter="animationEnterApplicationSidebar" :css="false">
        <div
          v-if="delayedShowSidebar"
          ref="sidebar-wrapper"
          :class="{
            '-ml-72': !sidebarOpen && !cursorIsOnLeftEdge,
          }"
          class="fixed inset-y-0 flex w-72 max-w-[80%] flex-col"
          style="transition: margin-left 0.3s"
        >
          <!-- Sidebar component, swap this element with another sidebar if you like -->

          <LeftSidebar />
        </div>
      </Transition>
    </Teleport>

    <div
      :class="{
        'md:ml-72': sidebarOpen && delayedShowSidebar,
        // 'transition-all': token,
      }"
      class="h-screen max-w-full overflow-clip transition-all duration-700"
    >
      <div class="container relative mx-auto h-0 overflow-visible">
        <Transition
          appear
          @enter="animationEnterApplicationHeader"
          :css="false"
        >
          <ApplicationHeader
            v-if="space && !$route.meta.hideHeader"
            ref="header"
            :sidebarDesktopOpen="sidebarOpen"
            :scrollY="scrollY"
            @toggleSidebar="toggleSidebar"
            @toggleSidebarMobile="sidebarOpen = !sidebarOpen"
            @toggleCommandPalette="showCommandPalette = !showCommandPalette"
            @mounted="initResizeObserverHeader"
            class="sticky z-10 w-full px-8 py-4"
          />
        </Transition>
      </div>
      <main
        ref="main"
        id="main"
        :class="focus ? 'overflow-y-hidden' : 'overflow-y-auto'"
        class="h-full overflow-x-hidden"
        style="
          transition:
            padding-top 1s ease,
            padding-bottom 1s ease;
          -webkit-transform: translateZ(0);
          scrollbar-gutter: stable both-edges; /*scrollbar-gutter: stable both-edges*/
        "
      >
        <div class="mx-auto">
          <!-- I removed this for the route change effect overflow-x-hidden overflow-y-hidden -->
          <slot />
        </div>
      </main>
      <!-- <div
        id="application-footer-wrapper"
        class="container relative mx-auto h-0 overflow-visible"
      > -->
      <Transition @enter="animationEnterApplicationFooter" :css="false">
        <ApplicationFooter
          v-if="space && showFooter"
          ref="footer"
          :entry="entry"
          :scrollY="scrollY"
          @toggleSidebarMobile="sidebarOpen = !sidebarOpen"
          @toggleCommandPalette="showCommandPalette = !showCommandPalette"
          @mounted="initResizeObserverFooter"
          class="container sticky z-10 mx-auto w-full px-8 py-4"
        />
      </Transition>
      <!-- </div> -->
    </div>

    <CommandPalette
      :isOpen="showCommandPalette"
      @onClose="showCommandPalette = false"
    />
    <DraggableWrapper />
    <Toasts />
    <Undos />
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import Toasts from "./helpers/Toasts.vue";
import Undos from "./helpers/Undos.vue";

import LeftSidebar from "./helpers/LeftSidebar.vue";
import DraggableWrapper from "./partials/DraggableWrapper.vue";
</script>

<!-- eslint-disable vue/no-unused-components -->
<script>
import { Lazying } from "./mixins/Lazying";
import anime from "animejs";
import { Touring } from "./mixins/Touring";
import { throttle } from "lodash";

export default {
  provide() {
    return {
      observeIntersection: () => document.getElementById("main"),
    };
  },
  mixins: [Lazying, Touring],
  created() {
    this.lastReload = this.$moment();
    this.detectFocusOut();
    if (!this.user && this.entries.length) {
      this.$store.dispatch("set", { key: "entries", val: [] });
    }
  },
  data() {
    return {
      lastReload: null,
      showNavbar: true,
      scrollY: 0,
      // lastScrollPosition: 0,
      sidebarOpen:
        window.innerWidth >= 768
          ? this.$store.getters.user?.settings?.show_navigation
          : false,
      showCommandPalette: false,
      time_trackings: [],

      // OBSERVERS
      resizeObserverHeader: null,
      resizeObserverFooter: null,

      cursorIsOnLeftEdge: false,
      cursorTimeout: null,
      // tour: null,

      // UX
      delayedShowSidebar: false,
    };
  },

  mounted() {
    this.delayedShowSidebar = this.showSidebar;
    if (this.$enableAnalytics) {
      window._paq.push(["setUserId", this.user?.email]);
      if (this.$route && this.$route.href) {
        window._paq.push(["setDocumentTitle", this.getDocumentTitle()]);
        window._paq.push(["setCustomUrl", this.$route.href]);
      }
      window._paq.push(["trackPageView"]);
    }
    if (this.token) {
      this.$store.dispatch("retrieveUser");
      this.$store.dispatch("retrieveTours");
    }
    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onTouchMove, { passive: true });
    window.addEventListener("keydown", this.handleKeyDown);

    document.addEventListener("visibilitychange", this.visibilityChange);

    this.debouncedOnScroll = throttle(this.onScroll, 200);
    this.$refs.main.addEventListener("scroll", this.debouncedOnScroll);

    this.$onIdle(() => {
      this.$store.dispatch("initialized", true);
    });
  },
  beforeUnmount() {
    if (this.resizeObserverHeader) this.resizeObserverHeader.disconnect();
    if (this.resizeObserverFooter) this.resizeObserverFooter.disconnect();
    window.removeEventListener("focus", this.onWindowFocusChange);
    window.removeEventListener("blur", this.onWindowFocusChange);
    window.removeEventListener("pageshow", this.onWindowFocusChange);
    window.removeEventListener("pagehide", this.onWindowFocusChange);
    window.removeEventListener("resize", this.onWindowResize);

    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("touchmove", this.onTouchMove, {
      passive: true,
    });
    window.removeEventListener("keydown", this.handleKeyDown);

    document.removeEventListener("visibilitychange", this.visibilityChange);

    this.$refs.main.removeEventListener("scroll", this.debouncedOnScroll);
  },
  watch: {
    showSidebar: function (n) {
      if (n)
        setTimeout(() => {
          this.delayedShowSidebar = n;
        }, 2000);
      else this.delayedShowSidebar = n;
    },
    // entry: function (n, o) {
    //   // console.log(this.entry.name);
    //   // // add entry color to body class
    //   // document.body.classList.remove("from-black");
    //   // document.body.classList.remove("from-white");
    //   // document.body.classList.remove("from-" + o.color + "-50");
    //   // document.body.classList.add("from-" + n.color + "-50");
    //   // remove body class
    // },
    token: function () {
      if (this.token) {
        this.$store.dispatch("retrieveUser");
        this.$store.dispatch("retrieveTours");
      }
    },
    $route: function (to) {
      if (this.$enableAnalytics && to && to.href) {
        window._paq.push(["setDocumentTitle", this.getDocumentTitle()]);
        window._paq.push(["setCustomUrl", to.href]);
        window._paq.push(["trackPageView"]);
      }
      this.handleSidebarOpen();

      // Reset selected entries
      if (this.$store.getters.selected.length) {
        this.$store.dispatch("selected", null);
      }
    },
    user: function () {
      if (this.user) {
        if (this.$enableAnalytics) {
          window._paq.push(["setUserId", this.user?.email]);
        }

        this.sidebarOpen =
          window.innerWidth >= 768
            ? this.user?.settings?.show_navigation
            : false;
      }
      //this.checkOnboarding();
    },
    // tours: function () {
    //   this.startTourIfOpen();
    // },
    focus: function () {
      if (this.$Cypress) {
        return;
      }
      if (this.focus) {
        anime({
          targets: this.$refs.shell,
          scale: 0.75,
          translateY: "80%",
          duration: 800,
        });
      } else {
        anime({
          targets: this.$refs.shell,
          scale: 1,
          translateY: 0,
          duration: 800,
        });
      }
    },
  },
  computed: {
    showSidebar() {
      return this.$route.meta.showSidebar || this.$Cypress;
    },
    showFooter() {
      return this.$route.meta.showSidebar || this.$Cypress;
    },
    focus() {
      return this.$store.getters.focus;
    },
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },

    entry() {
      return this.entries.find((e) => e.id == this.$route.params.id);
    },

    entry2() {
      return this.entries.find((e) => e.id == this.$route.params.id2);
    },
    space() {
      return this.$store.getters.space;
    },
    anchors() {
      return this.entries
        ?.filter((e) => e.anchor)
        .sort((a, b) => {
          if (a.anchor.position > b.anchor.position) return 1;
          if (a.anchor.position < b.anchor.position) return -1;
          return 0;
        });
    },
  },
  methods: {
    animationEnterApplicationHeader(el, done) {
      this.$anime({
        targets: el,
        translateY: [-100, 0],
        opacity: [0, 1],
        delay: 1000,
        complete: done,
      });
    },
    animationEnterApplicationFooter(el, done) {
      this.$anime({
        targets: el,
        translateY: [100, 0],
        opacity: [0, 1],
        delay: 1500,
        complete: done,
      });
    },
    animationEnterApplicationSidebar(el, done) {
      this.$anime({
        targets: el,
        translateX: [-100, 0],
        opacity: [0, 1],
        delay: 250,
        complete: done,
      });
    },
    onMouseMove(e) {
      if (!navigator.userAgent.includes("Mobile")) {
        if (!this.sidebarOpen) {
          if (
            e.clientX < 20 ||
            (this.cursorIsOnLeftEdge &&
              this.$refs["sidebar-wrapper"] &&
              e.clientX < this.$refs["sidebar-wrapper"].offsetWidth)
          ) {
            this.cursorIsOnLeftEdge = true;
            if (this.cursorTimeout) {
              clearTimeout(this.cursorTimeout);
              this.cursorTimeout = null;
            }
          } else if (
            this.cursorTimeout === null &&
            this.cursorIsOnLeftEdge &&
            this.$refs["sidebar-wrapper"] &&
            e.clientX > this.$refs["sidebar-wrapper"].offsetWidth
          ) {
            if (this.cursorTimeout) clearTimeout(this.cursorTimeout);
            this.cursorTimeout = setTimeout(() => {
              this.cursorIsOnLeftEdge = false;
              this.cursorTimeout = null;
            }, 500);
          }
        }
      }
    },

    /**
     * Close the sidebar if the user moves the touch event to the right
     */
    onTouchMove(e) {
      if (
        e.touches &&
        e.touches.length &&
        this.sidebarOpen &&
        window.innerWidth < 768 // allow tablets
      ) {
        if (e.touches[0].clientX > this.$refs["sidebar-wrapper"].offsetWidth) {
          this.sidebarOpen = false;
        }
      }
    },
    visibilityChange() {
      if (document.visibilityState === "visible") {
        this.$store.dispatch("timestamp");
      }
    },
    initResizeObserverHeader() {
      this.resizeObserverHeader = new ResizeObserver(() => {
        this.$refs.main.style.paddingTop =
          "calc(" +
          (this.$refs.header !== null
            ? this.$refs.header.$el.offsetHeight
            : 0) +
          "px + env(safe-area-inset-top, 0px))";
      });
      this.resizeObserverHeader.observe(this.$refs.header.$el);
    },
    initResizeObserverFooter() {
      this.resizeObserverFooter = new ResizeObserver(() => {
        this.$refs.main.style.paddingBottom =
          "calc(" +
          this.$refs.footer?.$el.offsetHeight +
          "px + env(safe-area-inset-bottom, 0px)";
      });
      this.resizeObserverFooter.observe(this.$refs.footer.$el);
    },
    getDocumentTitle() {
      return this.entry
        ? "Entry: " +
            this.entry.name +
            (this.entry2 ? " / " + this.entry2.name : "")
        : this.$route.meta.title
        ? this.$route.meta.title
        : this.$route.name
        ? this.$route.name.charAt(0).toUpperCase() + this.$route.name.slice(1)
        : "Pensive";
    },
    onWindowFocusChange(e) {
      let inView = false;
      if ({ focus: 1, pageshow: 1 }[e.type]) {
        if (inView) return;
        inView = true;
      } else if (inView) {
        inView = false;
      }
      if (inView) {
        if (
          this.lastReload.format("YYYY-MM-DD") !=
          this.$moment().format("YYYY-MM-DD")
        ) {
          location.reload();
        } else {
          this.$store.dispatch("timestamp");
        }
      }
    },
    detectFocusOut() {
      window.addEventListener("focus", this.onWindowFocusChange);
      window.addEventListener("blur", this.onWindowFocusChange);
      window.addEventListener("pageshow", this.onWindowFocusChange);
      window.addEventListener("pagehide", this.onWindowFocusChange);
    },
    onWindowResize() {
      this.handleSidebarOpen();
    },
    handleSidebarOpen() {
      if (window.innerWidth < 768) this.sidebarOpen = false;
      else if (this.user)
        this.sidebarOpen = this.user?.settings?.show_navigation;
    },
    toggleSidebar(save) {
      this.sidebarOpen = !this.sidebarOpen;
      if (save) {
        this.user.settings = {
          ...this.user.settings,
          show_navigation: this.sidebarOpen,
        };
        this.$store.dispatch("push", {
          event: "user_update",
          params: { user: this.user },
          user: this.user,
        });
      }
    },
    onScroll() {
      // Get the current scroll position
      // eslint-disable-next-line no-unreachable
      const currentScrollPosition = this.$refs.main.scrollTop;
      this.scrollY = currentScrollPosition;

      // // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      // if (currentScrollPosition < 0) {
      //   return;
      // }
      // // Stop executing this function if the difference between
      // // current scroll position and last scroll position is less than some offset
      // if (
      //   currentScrollPosition - this.lastScrollPosition < 60 &&
      //   currentScrollPosition - this.lastScrollPosition > -20
      // ) {
      //   return;
      // }

      // // Here we determine whether we need to show or hide the navbar
      // //this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      // // Set the current scroll position as the last scroll position
      // this.lastScrollPosition = currentScrollPosition;
    },
    handleKeyDown(event) {
      // console.log(event);
      if (
        event.target.tagName !== "INPUT" &&
        event.target.isContentEditable !== true
      ) {
        if (event.metaKey && event.keyCode === 219) {
          window.history.back();
        }
        if (event.metaKey && event.keyCode === 221) {
          window.history.forward();
        }

        if (!this.$route.params.id2) {
          if (event.keyCode === 77 && !event.repeat && !event.metaKey) {
            if (this.$windowSize.width < 768) {
              this.sidebarOpen = !this.sidebarOpen;
            } else {
              this.toggleSidebar(true);
            }
          }
          // if (event.keyCode === 70 && !event.repeat && !event.metaKey) {
          //   this.$store.dispatch(
          //     "focus",
          //     this.$store.getters.focus ? null : this.entry,
          //   );
          // }
          if (event.key === "h" && !event.repeat && !event.metaKey) {
            this.$router.push({
              name: "entry",
              params: { id: this.anchors[0].id },
            });
          }
          if (event.metaKey && event.keyCode === 75) {
            this.showCommandPalette = !this.showCommandPalette;
          }
        }
      }
    },
  },
};
</script>
