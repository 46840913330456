<template>
  <div class="flex flex-col gap-5" data-test="sense_builder">
    <!-- {{ children.map((e) => e.name) }} -->
    <div :class="$style.senseBuilder_content">
      <SectionHeader url="https://help.pensive.io/senses">
        <template #title> <FilterIcon size="16" /> Sense Filters </template>
      </SectionHeader>
      <FilterBuilder
        v-model="sense.filters"
        @update:modelValue="
          (filters) => {
            sense = { ...sense, filters };
          }
        "
      />

      <!--
      <PopoverHelper class="pt-2">
        <template #button>
          <ButtonComponent color="secondary">
            <MagnifyingGlassIcon class="h-4 w-4" />
            {{ filterEntries(filters).length }} Entries
          </ButtonComponent>
        </template>
        <div class="min-w-64 p-2">
          <OutputDisplay
            v-model="output"
            :entry="entry"
            :display="{
              name: true,
              description: false,
              schedule: false,
              status: false,
              routine: false,
              priority: false,
              procrastination: false,
              output: false,
            }"
            :editable="false"
            class="min-w-min"
          />
        </div>
      </PopoverHelper>
    -->
      <SectionHeader url="https://help.pensive.io/senses">
        <template #title> Aggregates </template>
      </SectionHeader>

      <div class="flex flex-col gap-x-2 text-xs dark:text-neutral-400">
        <span><strong>Possible Values:</strong></span>
        <span v-for="(key, index) in options" :key="index" class="uppercase">
          {{ key.title }}:
          {{
            filterEntries(filters)
              .map((e) =>
                key.value == "count" ? 1 : $resolveField(e, key.value) || 0,
              )
              .reduce((a, b) => a + b, 0)
          }}
          {{ getUnit(key.value) }}</span
        >
      </div>
      <fieldset
        v-for="(o, k) in sense.aggregates"
        :key="k"
        :class="$style.filterRow"
        data-test="filter_builder_row"
      >
        <div :class="$style.filterRow_left" class="flex items-center gap-2">
          <span
            :class="[
              'bg-' +
                (getAggregatedResults([o], children).filter((a) => !a.ok).length
                  ? 'red'
                  : 'green') +
                '-400',
              'inline-block h-2 w-2 flex-shrink-0 rounded-full',
            ]"
            class="order-last ml-auto md:order-first md:ml-0"
            aria-hidden="true"
          />
          <SelectMenu
            v-model="o.key"
            :options="options"
            :ref="'key_select_' + k"
            @update:modelValue="
              (key) => {
                o.key = key;
                $emit('update:modelValue', sense);
              }
            "
            color="secondary"
          />
          <SelectMenu
            v-if="o.key || o.op"
            v-model="o.op"
            :options="
              ops(o.key) /*.filter((op) =>
              ['gt', 'gte', 'eq', 'neq', 'lte', 'lt'].includes(op),
            )
              */
            "
            :render="(val) => opToSign(val)"
            @update:modelValue="
              (op) => {
                o.op = op;
                $emit('update:modelValue', sense);
              }
            "
            color="secondary"
          />

          <component
            v-if="
              vals(o.key, o.op).length &&
              ['DatePicker', 'TimePicker'].includes(vals(o.key, o.op)[0])
            "
            :is="vals(o.key, o.op)[0]"
            v-bind="vals(o.key, o.op)[1]"
            v-model="o.val[0]"
            @update:modelValue="
              (val) => {
                o.val = [val];
                $emit('update:modelValue', sense);
              }
            "
            color="secondary"
            data-test="filter_builder_val"
            class="rounded border px-2 py-1 text-xs dark:border-neutral-700"
          />
          <component
            v-if="
              vals(o.key, o.op).length &&
              !['DatePicker', 'TimePicker'].includes(vals(o.key, o.op)[0])
            "
            :is="vals(o.key, o.op)[0]"
            v-bind="vals(o.key, o.op)[1]"
            v-model="o.val"
            @update:modelValue="
              (val) => {
                o.val = val;
                $emit('update:modelValue', sense);
              }
            "
            data-test="filter_builder_val"
            :class="{
              'rounded border px-2 py-1 text-xs dark:border-neutral-700':
                vals(o.key, o.op)[0] == 'LinksBuilder',
            }"
            color="secondary"
          />
        </div>

        <div :class="$style.filterRow_right">
          <ButtonComponentNew
            @click="
              [sense.aggregates.splice(k, 1), $emit('update:modelValue', sense)]
            "
            color="secondary"
            size="sm"
            :class="$style.buttonRemove"
            data-test="sense_builder_delete_row"
            :style="{
              // '--ps-button-color': 'var(--ps-color-red-500)',
              '--ps-button-color-hover': 'var(--ps-color-red-600)',
            }"
          >
            <TrashIcon size="16" stroke-width="1.5" />
          </ButtonComponentNew>
        </div>
      </fieldset>

      <fieldset class="flex justify-between">
        <ButtonComponentNew
          @click.stop="
            aggregates.push({ key: null, op: null, val: [] }),
              $emit('update:modelValue', sense)
          "
          :class="$style.buttonAdd"
        >
          <PlusIcon size="16" /> Add
        </ButtonComponentNew>
        <ButtonComponentNew
          @click.stop="
            aggregates.push({ key: null, op: null, val: [] }),
              $emit('update:modelValue', sense)
          "
          :class="[$style.buttonDelete]"
        >
          <TrashIcon size="16" /> Delete Sense
        </ButtonComponentNew>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { Scheduling } from "../mixins/Scheduling";
import { defineAsyncComponent } from "vue";

import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import { Statusing } from "../mixins/Statusing";
import { Filtering } from "../mixins/Filtering";
// import Output from "../addons/Output.vue";
import { Aggregate } from "../mixins/Aggregate";

import { FilterIcon, XIcon, TrashIcon, PlusIcon } from "lucide-vue-next";

export default {
  mixins: [Filtering, Scheduling, Statusing, Aggregate],
  props: {
    modelValue: Object,
    entry: Object,
  },
  components: {
    SectionHeader: defineAsyncComponent(() =>
      import("@/components/helpers/SectionHeader.vue"),
    ),
    FilterBuilder: defineAsyncComponent(() =>
      import("@/components/builders/FilterBuilder.vue"),
    ),
    OptionPicker: defineAsyncComponent(() =>
      import("@/components/builders/OptionPicker.vue"),
    ),
    DatePicker: defineAsyncComponent(() =>
      import("@/components/builders/DatePicker.vue"),
    ),
    TimePicker: defineAsyncComponent(() =>
      import("@/components/builders/TimePicker.vue"),
    ),
    LinksBuilder: defineAsyncComponent(() =>
      import("@/components/links/partials/LinksBuilder.vue"),
    ),
    StatusBuilder: defineAsyncComponent(() =>
      import("@/components/builders/StatusBuilder.vue"),
    ),
    TextPicker: defineAsyncComponent(() =>
      import("@/components/builders/TextPicker.vue"),
    ),
    OutputDisplay: defineAsyncComponent(() =>
      import("@/components/output/OutputDisplay.vue"),
    ),
    AdjustmentsHorizontalIcon,
    FilterIcon,
    XIcon,
    TrashIcon,
    PlusIcon,
    MagnifyingGlassIcon,
  },
  data() {
    return {
      showLinkSearch: false,
      options: [
        { title: "Count", value: "count" },
        { title: "Schedule Duration", value: ["schedule", "duration"] },
      ],
    };
  },
  computed: {
    sense: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    children() {
      return this.filterEntries(this.filters);
    },
    filters() {
      return this.sense.filters;
    },
    output() {
      return { data: this.sense.filters };
    },
    aggregates() {
      return this.sense.aggregates;
    },
    statuses() {
      return this.getOwnAvailableStatuses(this.entry, true);
    },
    validatedValue() {
      return this.value;
    },
  },
};
</script>

<style module lang="scss">
.senseBuilder_content {
  @apply flex flex-col gap-4;
}

.button {
  &Add,
  &Delete {
    composes: buttonBordered from "@/styles/buttons.module.scss";
    margin: -0.25rem;
  }
  &Remove,
  &Delete {
    composes: buttonRemove from "@/styles/buttons.module.scss";
  }
}
</style>
