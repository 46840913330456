<template>
  <section
    :data-has-mouseenter="true"
    @mouseenter="
      (e) =>
        onMouseEnter(e, {
          id: null,
          schema: dropSchema,
        })
    "
    @mouseleave="onMouseLeave"
  >
    <slot />
  </section>
</template>

<script>
import { DragAndDrop } from "../mixins/DragAndDrop";
export default {
  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
  },
  mixins: [DragAndDrop],
  methods: {
    // eslint-disable-next-line no-unused-vars
    // setSchema(params) {
    //   //   console.log("DropZone::setSchema", params);
    //   this.$store.dispatch("schema", this.dropSchema);
    // },
    postDropActions(params) {
      this.$emit("onDrop", params);
    },
  },
};
</script>
