<template>
  <input
    ref="reference"
    :value="internalVisibleTime"
    @input="internalVisibleTime = $event.target.value"
    type="text"
    @focus="handleOnFocus"
    @blur="handleOnBlur"
    @keydown.enter.stop.prevent="$refs.reference.blur()"
    :class="$style.input"
    data-test="duration_picker_input"
    v-bind="$attrs"
  />
  <div
    ref="floating"
    v-if="showPopover"
    :class="$style.popover"
    :style="floatingStyles"
  >
    <div
      v-for="(time, key) in times"
      :key="key"
      @click.prevent="
        () => {
          internalVisibleTime = $moment
            .utc($moment.utc(basis).format('YYYY-MM-DD') + ' ' + time)
            .local()
            .format('HH:mm');
          duration = $moment
            .utc($moment.utc(basis).format('YYYY-MM-DD') + ' ' + time)
            .diff($moment.utc(basis), 'seconds');
        }
      "
      :data-test="'time_picker_set_' + time"
    >
      <slot name="timeSuggestion" :time="time">
        <button
          v-show="
            $moment
              .utc($moment.utc(basis).format('YYYY-MM-DD') + ' ' + time)
              .isAfter(basis ? $moment.utc(basis) : $moment.utc())
          "
          :class="$style.buttonTimeSuggestion"
        >
          <span>
            {{
              $moment
                .utc($moment().format("YYYY-MM-DD") + " " + time)
                .local()
                .format("HH:mm")
            }}
          </span>
          <span class="ml-auto text-neutral-500">
            {{ renderDuration(time) }}
          </span>
        </button>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: Number,
    basis: String,
  },

  data() {
    return {
      internalTime: "",
      internalVisibleTime: "",
      showPopover: false,
    };
  },
  watch: {
    duration(val) {
      this.internalDuration = val;
      this.calculateTime();
    },

    // eslint-disable-next-line no-unused-vars
    basis(val) {
      this.calculateTime();
    },
  },
  mounted() {
    this.internalDuration = this.time;
    this.calculateTime();
  },
  computed: {
    duration: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    times() {
      return Array.from({ length: 24 }, (_, hour) => {
        return ["00", "15", "30", "45"].map((minute) => {
          return `${String(hour).padStart(2, "0")}:${minute}`;
        });
      }).flat();
    },
  },
  methods: {
    renderDuration(time) {
      const timeMoment = this.$moment.utc(
        this.$moment().format("YYYY-MM-DD") + " " + time,
      );
      return this.$moment
        .utc(this.$moment(timeMoment).diff(this.$moment.utc(this.basis)))
        .format("H[h] mm[m]", {
          stopTrim: "m",
        });
    },
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },
    calculateTime() {
      if (this.basis === null) {
        this.internalVisibleTime = "";
        return;
      }
      this.internalVisibleTime = this.$moment
        .utc(this.basis)
        .add(this.duration ? this.duration : 0, "seconds")
        .local()
        .format("HH:mm");
    },

    handleOnFocus() {
      this.showPopover = true;
      this.$refs.reference.select();
    },
    handleOnBlur() {
      this.delayShowPopover(false);
      if (this.internalVisibleTime === "") {
        this.duration = null;
        return;
      }

      if (!this.isValidTime(this.internalVisibleTime)) {
        this.calculateTime();
        return;
      }

      if (this.isValidTime(this.internalVisibleTime)) {
        const endTimeMoment = this.$moment(
          this.$moment.utc(this.basis).format("YYYY-MM-DD") +
            " " +
            this.internalVisibleTime,
        );
        if (endTimeMoment.isValid()) {
          this.$refs.reference.blur();
          this.duration = endTimeMoment
            .utc()
            .diff(this.$moment.utc(this.basis), "seconds");
        }
      }
    },
    // handleOnEnter() {
    //   if (this.isValidTime(this.internalVisibleTime)) {
    //     const endTimeMoment = this.$moment(
    //       this.$moment.utc(this.basis).format("YYYY-MM-DD") +
    //         " " +
    //         this.internalVisibleTime,
    //     );
    //     if (endTimeMoment.isValid()) {
    //       this.$refs.reference.blur();
    //       this.duration = endTimeMoment
    //         .utc()
    //         .diff(this.$moment.utc(this.basis), "seconds");
    //     }
    //   }
    // },
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    },
  },
};
</script>

<style module lang="scss">
.input {
}
.popover {
  composes: panel from "@/styles/panels.module.scss";
  max-height: 20rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--ps-base-background-color);
  z-index: 10;
}

.buttonTimeSuggestion {
  @apply flex items-center gap-1;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
}
</style>
