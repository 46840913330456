<template>
  <div
    v-if="input"
    class="flex flex-col gap-2 divide-y dark:divide-neutral-700"
    data-test="input_builder"
  >
    <LabelHelper> Input Settings </LabelHelper>
    <fieldset
      v-for="([key], index) in Object.entries(input.schema).filter((v) => v)"
      :key="index"
      class="flex flex-col gap-y-1.5 pt-2 first:pt-0"
    >
      <div class="flex items-center gap-2">
        <ButtonComponent
          @click="removeField(key)"
          data-test="input_builder_delete_row"
          size="xs"
          :color="color"
          :title="
            'Remove ' +
            key.charAt(0).toUpperCase() +
            key.slice(1) +
            ' from this Input'
          "
        >
          <XMarkIcon class="h-4 w-4" />
          {{ key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ") }}
        </ButtonComponent>
        <OptionPicker
          v-if="key == 'links'"
          v-model="input.schema.links.op"
          :options="['set', 'add']"
          @update:modelValue="
            (op) => {
              input.schema.links.op = op;
              input = { ...input, ...{ schema: input.schema } };
            }
          "
          :size="size"
          :color="color"
        />
      </div>

      <StatusSettings
        v-if="key == 'status'"
        v-model="status"
        :statuses="statuses"
        :full="true"
        :size="size"
        :variant="variant"
        :color="color"
        data-test="input_builder_status"
        class="self-start"
      />
      <div v-if="key == 'schedule'" class="flex flex-col gap-2 px-2">
        <div class="flex flex-wrap items-stretch justify-stretch gap-2">
          <LabelHelper class="flex w-10 items-center">Date:</LabelHelper>
          <SelectMenu
            v-if="!input.schema.schedule.date"
            :modelValue="null"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { date: val } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'No Date',
                value: null,
              },
              {
                title: 'Today',
                value: { op: 'today' },
              },
              {
                title: 'Tomorrow',
                value: { op: 'tomorrow' },
              },
              {
                title: 'Yesterday',
                value: { op: 'yesterday' },
              },
              {
                title: 'Next ...',
                value: { op: 'nextXDays' },
              },
              {
                title: 'Last ...',
                value: { op: 'lastXDays' },
              },
            ]"
            class="flex-1"
            data-test="input_builder_schedule_date"
          />
          <SelectMenu
            v-else
            v-model="input.schema.schedule.date.op"
            @update:modelValue="
              (val) => {
                if (val === null) {
                  input = $merge(input, {
                    schema: { schedule: { date: null } },
                  });
                } else {
                  input = $merge(input, {
                    schema: { schedule: { date: { op: val } } },
                  });
                }
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'No Date',
                value: null,
              },
              {
                title: 'Today',
                value: 'today',
              },
              {
                title: 'Tomorrow',
                value: 'tomorrow',
              },
              {
                title: 'Yesterday',
                value: 'yesterday',
              },
              {
                title: 'Next ...',
                value: 'nextXDays',
              },
              {
                title: 'Next ...',
                value: 'nextXWeeks',
                hidden: true,
              },
              {
                title: 'Next ...',
                value: 'nextXMonths',
                hidden: true,
              },
              {
                title: 'Next ...',
                value: 'nextXYears',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXDays',
              },
              {
                title: 'Last ...',
                value: 'lastXWeeks',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXMonths',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXYears',
                hidden: true,
              },
            ]"
            class="flex-1"
            data-test="input_builder_schedule_date"
          />
          <TextInput
            v-if="input.schema.schedule.date?.op?.includes('X')"
            v-model="input.schema.schedule.date.x"
            :validation="(val) => val.replace(/[^\d]/g, '')"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { date: { x: val } } },
                });
              }
            "
            class="flex-1 text-sm"
          />

          <SelectMenu
            v-if="input.schema.schedule.date?.op?.includes('next')"
            v-model="input.schema.schedule.date.op"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { date: { op: val } } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'Days',
                value: 'nextXDays',
              },
              {
                title: 'Weeks',
                value: 'nextXWeeks',
              },
              {
                title: 'Months',
                value: 'nextXMonths',
              },
              {
                title: 'Years',
                value: 'nextXYears',
              },
            ]"
            class="flex-1"
          />

          <SelectMenu
            v-if="input.schema.schedule.date?.op?.includes('last')"
            v-model="input.schema.schedule.date.op"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { date: { op: val } } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'Days',
                value: 'lastXDays',
              },
              {
                title: 'Weeks',
                value: 'lastXWeeks',
              },
              {
                title: 'Months',
                value: 'lastXMonths',
              },
              {
                title: 'Years',
                value: 'lastXYears',
              },
            ]"
            class="flex-1"
          />
        </div>
        <div class="flex flex-wrap items-stretch justify-stretch gap-2">
          <LabelHelper class="flex w-10 items-center">Time:</LabelHelper>
          <SelectMenu
            v-if="!input.schema.schedule.time"
            :modelValue="null"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { time: val } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'No Time',
                value: null,
              },
              {
                title: 'Now',
                value: { op: 'now' },
              },
              {
                title: 'Next ...',
                value: {
                  op: 'nextXHours',
                },
              },
              {
                title: 'Last ...',
                value: {
                  op: 'lastXHours',
                },
              },
            ]"
            class="flex-1"
            data-test="input_builder_schedule_time"
          />
          <SelectMenu
            v-else
            v-model="input.schema.schedule.time.op"
            @update:modelValue="
              (val) => {
                if (val === null) {
                  input = $merge(input, {
                    schema: { schedule: { time: null } },
                  });
                } else {
                  input = $merge(input, {
                    schema: { schedule: { time: { op: val } } },
                  });
                }
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'No Time',
                value: null,
              },
              {
                title: 'Now',
                value: 'now',
              },
              {
                title: 'Next ...',
                value: 'nextXHours',
              },
              {
                title: 'Next ...',
                value: 'nextXMinutes',
                hidden: true,
              },
              {
                title: 'Next ...',
                value: 'nextXSeconds',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXHours',
              },
              {
                title: 'Last ...',
                value: 'lastXMinutes',
                hidden: true,
              },
              {
                title: 'Last ...',
                value: 'lastXSeconds',
                hidden: true,
              },
            ]"
            class="flex-1"
            data-test="input_builder_schedule_time"
          />
          <TextInput
            v-if="input.schema.schedule.time?.op?.includes('X')"
            v-model="input.schema.schedule.time.x"
            :validation="(val) => val.replace(/[^\d]/g, '')"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { time: { x: val } } },
                });
              }
            "
            class="flex-1 text-sm"
          />

          <SelectMenu
            v-if="input.schema.schedule.time?.op?.includes('next')"
            v-model="input.schema.schedule.time.op"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { time: { op: val } } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'Hours',
                value: 'nextXHours',
              },
              {
                title: 'Minutes',
                value: 'nextXMinutes',
              },
              {
                title: 'Seconds',
                value: 'nextXSeconds',
              },
            ]"
            class="flex-1"
          />

          <SelectMenu
            v-if="input.schema.schedule.time?.op?.includes('last')"
            v-model="input.schema.schedule.time.op"
            @update:modelValue="
              (val) => {
                input = $merge(input, {
                  schema: { schedule: { time: { op: val } } },
                });
              }
            "
            :teleport="false"
            :options="[
              {
                title: 'Hours',
                value: 'lastXHours',
              },
              {
                title: 'Minutes',
                value: 'lastXMinutes',
              },
              {
                title: 'Seconds',
                value: 'lastXSeconds',
              },
            ]"
            class="flex-1"
          />
        </div>
      </div>
      <LinksBuilder
        v-if="key == 'links'"
        v-model="input.schema.links.val"
        @update:modelValue="
          (val) => {
            input.schema.links.val = val;
            input = { ...input, ...{ schema: input.schema } };
          }
        "
        :size="size"
        :variant="variant"
        :color="color"
      />
      <TextInput
        v-if="key == 'description'"
        v-model="input.schema.description.content"
        @update:modelValue="
          (val) => {
            input.schema.description.content = val;
            input = { ...input, ...{ schema: input.schema } };
          }
        "
      />
      <TextInput
        v-if="key == 'priority'"
        v-model="input.schema.priority.level"
        :validation="(val) => val.replace(/[^1234]/g, '')"
        @update:modelValue="
          (val) => {
            input.schema.priority.level = val;
            input = { ...input, ...{ schema: input.schema } };
          }
        "
      />
      <TextInput
        v-if="key == 'procrastination'"
        v-model="input.schema.procrastination.count"
        :validation="(val) => val.replace(/[^\d]/g, '')"
        @update:modelValue="
          (val) => {
            input.schema.procrastination.count = val;
            input = { ...input, ...{ schema: input.schema } };
          }
        "
      />
      <div v-if="key == 'time_tracking'">
        <span class="mx-2 text-xs text-neutral-500 dark:text-neutral-600">
          Start tracking immediately
        </span>
      </div>
    </fieldset>

    <div class="flex flex-col items-start gap-2 pt-2 first:pt-0">
      <ButtonComponent
        v-for="(addon, key) in availableAddons.filter((a) => !input.schema[a])"
        :id="'input_builder_add_' + addon"
        :key="key"
        @click="addFieldToInput(addon)"
        size="xs"
        :color="color"
        :title="
          'Add a ' +
          addon.charAt(0).toUpperCase() +
          addon.slice(1) +
          ' to this Input'
        "
        :data-test="'input_builder_add_' + addon"
      >
        <PlusSmallIcon class="h-4 w-4" />
        {{ addon.charAt(0).toUpperCase() + addon.slice(1).replace("_", " ") }}
      </ButtonComponent>
    </div>

    <div class="flex items-center pt-2">
      <a
        v-if="position != 'dialog'"
        href="https://help.pensive.io/inputs"
        target="_blank"
        class="text-sm text-blue-500 hover:text-blue-700 hover:underline"
      >
        <QuestionMarkCircleIcon class="mr-1 inline h-4 w-4" />
        <span>Learn more about inputs</span>
      </a>
      <ButtonComponent
        @click="input = null"
        :size="size"
        class="ml-auto hover:text-red-500"
        color="secondary"
        title="Delete Input"
        data-test="entry_input_delete"
      >
        <TrashIcon class="h-4 w-4" /> <span> Delete </span>
      </ButtonComponent>
    </div>
  </div>

  <div v-else>
    <ButtonComponent
      @click="input = addonBlueprint('input', entry, this)"
      :size="size"
      :variant="variant"
      :color="color"
      title="Add Input"
      data-test="entry_input_create"
      class="w-full"
    >
      <TextCursorInput size="20" />
      <span v-if="!['round', 'minimal'].includes(variant)"> Input </span>
      <template #help>
        <video
          src="https://help.pensive.io/videos/inputs_schema.mp4"
          autoplay
          loop
          playsinline
          class="w-96"
        ></video>
      </template>
    </ButtonComponent>
  </div>
</template>

<script setup>
import { TextCursorInput } from "lucide-vue-next";
import { addonBlueprint } from "@/addonBlueprint";
</script>
<script>
import { PlusSmallIcon, XMarkIcon } from "@heroicons/vue/24/outline";

import { defineAsyncComponent } from "vue";
import { Statusing } from "../mixins/Statusing";
import OptionPicker from "../builders/OptionPicker.vue";
import { Addon } from "../mixins/Addon";
import { Scheduling } from "../mixins/Scheduling";

export default {
  props: {
    position: String,
    color: {
      type: String,
      default: "white",
    },
  },
  mixins: [Statusing, Scheduling, Addon],
  components: {
    LinksBuilder: defineAsyncComponent(() =>
      import("@/components/links/partials/LinksBuilder.vue"),
    ),
    TextInput: defineAsyncComponent(() =>
      import("@/components/helpers/TextInput.vue"),
    ),
    XMarkIcon,
    PlusSmallIcon,
    OptionPicker,
  },
  watch: {
    "input.schema.schedule.date": function (val) {
      if (this.input) {
        if (val && val.op && val.op.includes("X")) {
          if (!this.input.schema.schedule.date.x) {
            this.input.schema.schedule.date.x = 1;
          }
        } else if (this.input.schema?.schedule?.date) {
          delete this.input.schema.schedule.date.x;
        }
      }
    },
    "input.schema.schedule.time": function (val) {
      if (this.input) {
        if (val && val.op && val.op.includes("X")) {
          if (!this.input.schema.schedule.time.x) {
            this.input.schema.schedule.time.x = 1;
          }
        } else if (this.input.schema?.schedule?.time) {
          delete this.input.schema.schedule.time.x;
        }
      }
    },
  },
  data() {
    return {
      //completion: null,
      schedule: null,
      anchor: null,
      links: null,
      newInput: false,
      availableAddons: [
        "links",
        "status",
        //"completion",
        "schedule",
        "anchor",
        "description",
        "priority",
        "procrastination",
        "time_tracking",
      ],
      showLinkSearch: false,
    };
  },
  methods: {
    component(addon) {
      switch (addon) {
        // case "completion":
        //   return [
        //     "OptionPicker",
        //     {
        //       options: [[0], [1]],
        //       render: (v) => (v[0] ? "completed" : "uncompleted"),
        //     },
        //   ];
        case "schedule":
          return ["DatePicker"];
      }
    },
    addFieldToInput(addon) {
      switch (addon) {
        case "status":
          this.input.schema.status = this.statuses ? this.statuses[0].id : null;
          break;
        case "schedule":
          this.input.schema.schedule = {
            date: { op: "today" },
            time: null,
          };
          break;
        case "anchor":
          this.input.schema.anchor = {
            position: { op: "append" },
          };
          break;
        case "links":
          this.input.schema.links = { op: "set", val: [] };
          break;
        case "description":
          this.input.schema.description = { content: "" };
          break;
        case "priority":
          this.input.schema.priority = { level: 2 };
          break;
        case "procrastination":
          this.input.schema.procrastination = { count: 1 };
          break;
        case "time_tracking":
          this.input.schema.time_tracking = { start_at: { op: "now" } };
          break;
      }

      this.input = {
        ...this.input,
        ...{ schema: this.input.schema },
      };

      this.newInput = false;
    },

    removeField(addon) {
      delete this.input.schema[addon];

      this.input = {
        ...this.input,
        ...{ schema: this.input.schema },
      };
    },
  },
  computed: {
    input: {
      get() {
        return this.modelValue;
      },
      set(input) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.input = input;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    validatedValue() {
      var value = {};
      // if (this.value.completion) value.completion = this.value.completion;
      if (this.value.status) value.status = this.value.status;
      if (this.value.schedule) value.schedule = this.value.schedule;
      if (this.value.anchor) value.anchor = this.value.anchor;
      if (this.value.links) value.links = this.value.links;
      if (this.value.description) value.description = this.value.description;
      if (this.value.priority) value.priority = this.value.priority;
      if (this.value.procrastination)
        value.procrastination = this.value.procrastination;
      return value;
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },

    statuses() {
      return this.$merge(
        this.getOwnAvailableStatuses(this.entry),
        JSON.stringify(this.getOwnAvailableStatuses(this.entry)) !=
          JSON.stringify(this.getAvailableStatuses(this.entry))
          ? this.getAvailableStatuses(this.entry)
          : [],
      );
    },
    status: {
      get() {
        return this.getStatusById(this.input.schema.status);
      },
      set(status) {
        this.input = {
          ...this.input,
          ...{ schema: { ...this.input.schema, ...{ status: status?.id } } },
        };
      },
    },
  },
};
</script>
