<template>
  <div>
    <ButtonComponent
      @click="(template.name = entry.name), (showSaveTemplate = true)"
      class="whitespace-nowrap rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-900"
      data-test="save_as_template_button"
      :size="size"
      :variant="variant"
      :color="color"
    >
      <SquaresPlusIcon class="h-4 w-4" />
      Save as Template
    </ButtonComponent>

    <ModalHelper :show="showSaveTemplate" @close="showSaveTemplate = false">
      <template #title> Save Template </template>

      <form @submit.prevent="addTemplate">
        <label
          for="template-name"
          class="block text-sm font-medium leading-6 text-gray-900"
        >
          Template Name
        </label>
        <div class="mt-2">
          <input
            v-model="template.name"
            type="text"
            name="template-name"
            id="template-name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <label
          for="about"
          class="mt-2 block text-sm font-medium leading-6 text-gray-900"
          >Description</label
        >
        <div class="mt-2">
          <textarea
            id="description"
            v-model="template.description"
            name="description"
            rows="3"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <fieldset class="mt-2">
          <div class="flex justify-between">
            <legend class="text-sm font-semibold leading-6 text-gray-900">
              Fields to include
            </legend>
            <button
              type="button"
              @click="
                () => {
                  Object.keys(template.include).forEach((key) => {
                    template.include[key] = !template.include[key];
                  });
                }
              "
              class="text-xs text-gray-500 hover:text-gray-900"
              data-test="template_toggle_all_includes_button"
            >
              Toggle all
            </button>
          </div>

          <div class="mt-6 space-y-6">
            <div class="relative grid grid-cols-2 gap-2 lg:grid-cols-3">
              <div
                v-for="index in Object.keys(template.include)"
                :key="index"
                class="relative flex gap-x-3"
              >
                <div class="flex h-6 items-center">
                  <input
                    v-model="template.include[index]"
                    :id="`template-include-${index}`"
                    :name="`template-include-${index}`"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    :data-test="`template_include_${index}`"
                  />
                </div>
                <div class="text-sm leading-6">
                  <label
                    for="template-include-status"
                    class="font-medium text-gray-900"
                  >
                    {{ index.charAt(0).toUpperCase() + index.slice(1) }}
                  </label>
                  <p class="text-xs text-gray-500">
                    Include the {{ index }} of the entry in the template.
                  </p>
                  <div v-if="index == 'schedule' && template.include[index]">
                    <input
                      v-model="template.relative_schedule"
                      id="template-relative-schedule"
                      name="template-relative-schedule"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      data-test="template_relative_schedule"
                    />
                    <span class="text-xs text-gray-500">
                      Use relative date
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            @click="showSaveTemplate = false"
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            data-test="cancel_save_template_button"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            data-test="save_template_button"
          >
            Save
          </button>
        </div>
      </form>
    </ModalHelper>
  </div>
</template>

<script>
import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "@/components/mixins/Addon";
export default {
  mixins: [Addon],
  data() {
    return {
      showSaveTemplate: false,
      template: {
        name: "",
        description: "",
        include: {
          status: true,
          description: true,
          schedule: true,
          links: true,
          output: true,
          input: true,
          priority: true,
          senses: true,
          routine: true,
          custom_fields: true,
          custom_statuses: true,
          // custom_values: true, TODO: implement custom values
        },
        relative_schedule: true,
      },
    };
  },
  computed: {
    schedule() {
      if (this.entry.schedule) {
        var schedule = {};

        if (this.entry.schedule?.date) {
          if (this.template.relative_schedule) {
            const now = this.$moment
              .utc()
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            const date = this.$moment.utc(
              this.entry.schedule.date + " 00:00:00",
            );
            const diff = date.diff(now, "days");
            schedule.date = { op: "nextXDays", x: diff };
          } else {
            schedule.date = this.entry.schedule.date;
          }
        }
        if (this.entry.schedule?.time) {
          if (this.template.relative_schedule) {
            // TODO: relative time
            schedule.time = this.entry.schedule.time;
          } else {
            schedule.time = this.entry.schedule.time;
          }
        }
        if (this.entry.schedule?.duration) {
          schedule.duration = this.entry.schedule.duration;
        }
        if (this.entry.schedule?.recurrence) {
          schedule.recurrence = this.entry.schedule.recurrence;
        }
        return schedule;
      }
      return null;
    },
  },
  methods: {
    addTemplate() {
      var payload = addonBlueprint("template", this.entry, this);

      if (this.template.include.status && this.entry.status_id) {
        payload.data.status_id = this.entry.status_id;
      }
      if (this.template.include.description && this.entry.description) {
        payload.data.description = { content: this.entry.description.content };
      }
      if (this.template.include.schedule && this.schedule) {
        payload.data.schedule = this.schedule;
      }
      if (this.template.include.links && this.entry.links.length) {
        payload.data.links = { op: "add", val: this.entry.links };
      }
      if (this.template.include.output && this.entry.output) {
        payload.data.output = {
          type: this.entry.output.type,
          filters: this.entry.output.filters,
          grouping: this.entry.output.grouping,
          sorting: this.entry.output.sorting,
        };
      }
      if (this.template.include.input && this.entry.input) {
        const input = this.entry.input.schema;
        payload.data.input = { schema: input };
      }
      if (this.template.include.priority && this.entry.priority) {
        payload.data.priority = { level: this.entry.priority.level };
      }

      // if (this.template.include.senses && this.entry.senses.length) {
      //   payload.data.senses = this.entry.senses.map((s) => {
      //     return {
      //       name: s.name,
      //       color: s.color,
      //       filters: s.filters,
      //       aggregates: s.aggregates,
      //       position: s.position,
      //     };
      //   });
      // }

      if (this.template.include.routine && this.entry.routine) {
        payload.data.routine = {
          ...addonBlueprint("routine", this.entry, this),
          ...{
            frequency: this.entry.routine.frequency,
            target: this.entry.routine.target,
          },
        };
        delete payload.data.routine.id;
        delete payload.data.routine.entry_id;
        delete payload.data.routine.logs;
        delete payload.data.routine.created_at;
        delete payload.data.routine.updated_at;
      }
      if (this.template.include.senses && this.entry.senses.length) {
        payload.data.senses = this.entry.senses.map((s) => {
          const sense = {
            ...addonBlueprint("sense", this.entry, this),
            ...{
              name: s.name,
              color: s.color,
              filters: s.filters,
              aggregates: s.aggregates,
            },
          };

          delete sense.id;
          delete sense.space_id;
          delete sense.entry_id;
          delete sense.created_at;
          delete sense.updated_at;

          return sense;
        });
      }
      if (this.template.include.custom_fields && this.entry.custom_fields) {
        payload.data.custom_fields = this.entry.custom_fields.map((f) => {
          return {
            ...addonBlueprint("custom_field", this.entry, this),
            ...{
              name: f.name,
              type: f.type,
              position: f.position,
            },
          };
        });
      }
      if (this.template.include.custom_statuses && this.entry.statuses.length) {
        payload.data.statuses = this.entry.statuses.map((s) => {
          return {
            ...addonBlueprint("status", this.entry, this),
            ...{
              name: s.name,
              type: s.type,
              position: s.position,
              color: s.color,
            },
          };
        });
      }

      payload = JSON.parse(
        JSON.stringify(payload).replaceAll(this.entry.id, "__ID__"),
      );
      payload = JSON.parse(
        JSON.stringify(payload).replaceAll(this.entry.space_id, "__SPACE_ID__"),
      );

      payload.entry_id = this.entry.id;
      payload.space_id = this.entry.space_id;
      this.$store.dispatch("push", {
        event: "template_create",
        params: { template: payload },
        entry: this.entry,
        undo: 1,
      });

      this.showSaveTemplate = false;
    },
  },
};
</script>
