<template>
  <ButtonComponent
    v-if="size == 'xs'"
    size="sm"
    color="secondary"
    :title="template.description"
    :data-test="'template_' + $slugify(template.name)"
  >
    <div>
      <IconHelper
        :name="template.icon"
        size="16"
        :class="`text-${template.data.color}-500`"
        class="inline"
      />
      {{ template.name }}
    </div>
  </ButtonComponent>
  <div
    v-else
    :class="{
      'sm:grid sm:grid-cols-4 sm:divide-x dark:divide-neutral-900': showPreview,
    }"
    class="group relative flex h-full flex-col gap-4"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="flex h-full flex-col justify-between gap-4">
      <div class="flex flex-col gap-2">
        <div class="flex items-center justify-between pb-2">
          <div
            v-if="template.icon"
            :class="[
              `bg-${template.data.color || 'neutral'}-${
                template.data.color ? '50' : '50'
              } dark:bg-${template.data.color || 'neutral'}-${
                template.data.color ? '950' : '950'
              }`,
            ]"
            class="self-start rounded-lg p-2"
          >
            <span
              :class="[
                `text-${template.data.color || 'neutral'}-${
                  template.data.color ? '500' : '500'
                }`,
                // ' text-white',
              ]"
            >
              <IconHelper :name="template.icon" size="24" />
            </span>
          </div>
          <slot name="top" />
        </div>
        <a
          class="font-semibold text-neutral-900 dark:text-neutral-400"
          :data-test="'template_' + $slugify(template.name)"
        >
          <!-- <span class="absolute inset-0 cursor-pointer" aria-hidden="true" /> -->
          {{ template.name }}
        </a>

        <p class="text-sm text-neutral-500">
          {{ template.description }}
        </p>
      </div>

      <div :class="{ 'sm:justify-end': showPreview }" class="flex">
        <ul
          class="flex flex-wrap gap-1.5 font-mono text-xs"
          :class="[
            `text-${template.data.color || 'neutral'}-${
              template.data.color ? '500' : '900'
            }`,
          ]"
        >
          <li
            v-for="tag in template.tags?.filter((tag) => !tag.includes('__'))"
            :key="tag"
          >
            #{{ tag }}
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="showPreview"
      class="relative hidden max-h-96 flex-col gap-4 sm:col-span-3 sm:flex sm:pl-4"
    >
      <span
        class="absolute right-0 top-0.5 rounded bg-neutral-100 px-2 py-1 text-xs dark:bg-neutral-900"
      >
        Preview
      </span>
      <TransitionGroupHelper :enter="{ translateY: [20, 0] }">
        <!-- {{ template.data }} -->
        <div key="templateEntry">
          <EntryDisplay
            ref="entry_display"
            :modelValue="templateEntry"
            :toggleRenderOutput="() => (showChildren = !showChildren)"
            data-delay="0"
            :disableViewportChecking="true"
            :disableInitialization="true"
            :editable="false"
            :disabled="true"
            position="template"
          />
        </div>

        <OutputDisplay
          v-if="showChildren && templateChildren.length"
          :modelValue="resolvedTemplate.data.output"
          :entry="templateEntry"
          :editable="false"
          :disabled="true"
          :entriesDisabled="true"
          position="template"
        />
        <InputDisplay
          v-if="showChildren && !templateChildren.length"
          :modelValue="resolvedTemplate.data.input"
          :entry="templateEntry"
          :editable="false"
          :disabled="true"
          position="template"
        />
      </TransitionGroupHelper>
    </div>

    <!-- <div class="flex flex-col justify-between gap-2 md:pl-4">
      <slot name="right">
        <div></div>
        <div class="flex justify-between">
          <div />
          <ButtonComponent color="primary">
            Select <IconHelper name="arrow-right" size="20" />
          </ButtonComponent>
        </div>
      </slot>
    </div> -->
    <!-- <div class="flex w-10 flex-col justify-between gap-8">
      <div class="p-2 vertical-rl">
        <ul
          class="font-mono flex gap-4 uppercase text-neutral-200 transition-colors group-hover:text-neutral-800"
        >
          <li>by Pensive</li>
        </ul>
      </div>

      <slot name="right">
        <IconHelper name="arrow-right" strokeWidth="1" />
      </slot>
    </div> -->
  </div>
</template>

<script>
import { Addon } from "../mixins/Addon";
import { Templating } from "../mixins/Templating";
import { Schema } from "../mixins/Schema";
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { defineAsyncComponent } from "vue";

export default {
  components: {
    OutputDisplay: defineAsyncComponent(() =>
      import("@/components/output/OutputDisplay.vue"),
    ),
  },
  provide() {
    return {
      overrideEntries: this.templateChildren,
      overrideStatuses: this.templateStatuses,
    };
  },
  mixins: [Addon, Templating, Schema, Statusing, Scheduling],
  props: {
    modelValue: Object,
    showPreview: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showChildren: true,
    };
  },
  mounted() {
    if (this.$refs.entry_display) this.$refs.entry_display.renderOutput = true;
  },
  computed: {
    template: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    resolvedTemplate() {
      const id = this.$nanoid();
      return this.resolveData(this.template, { __ID__: id });
    },
    templateEntry() {
      return {
        ...this.entrySchema(this.resolvedTemplate.data),
        ...{ temp: true, name: this.template.name },
      };
    },
    templateChildren() {
      return this.resolvedTemplate.entries.map((entry) => {
        return {
          ...this.entrySchema(this.resolveData(entry)),
          ...{ temp: true },
        };
      });
    },
    templateStatuses() {
      return this.resolvedTemplate.data.statuses;
    },
  },
  methods: {
    onMouseEnter() {
      // this.showChildren = true;
      // if (this.$refs.entry_display)
      //   this.$refs.entry_display.renderOutput = true;
    },
    onMouseLeave() {
      // this.showChildren = false;
      // if (this.$refs.entry_display)
      //   this.$refs.entry_display.renderOutput = false;
    },
  },
};
</script>
