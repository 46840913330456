<template>
  <Popover v-slot="{ open, close }">
    <PopoverButton
      ref="reference"
      class="flex h-full w-full focus-visible:outline-none dark:text-neutral-500"
      :class="buttonClass ? buttonClass : ''"
      :data-open="open"
    >
      <!-- class="flex items-center self-center" -->
      <slot name="button" />
    </PopoverButton>
    <Teleport :disabled="!teleport" to="body">
      <PopoverOverlay
        @click.stop.prevent
        @pointerdown.stop.prevent="close(), $emit('close')"
        @touchstart.stop.prevent
        class="fixed inset-0 z-10 bg-black opacity-0"
        :id="(id ? id + '_' : '') + 'overlay'"
        :data-test="(id ? id + '_' : '') + 'overlay'"
      />

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          v-slot="{ close }"
          class="fixed z-10 mt-3 max-w-sm transform px-4 sm:px-0 lg:max-w-3xl"
        >
          <div
            :style="floatingStyles"
            ref="floating"
            :class="{
              [`${$style.popoverPanel}`]: true,
              'overflow-y-auto': canOverflow, // note: this enables long filter lists to be scrollable and links builder in contextmenu
            }"
            style="max-height: calc(100vh - 20px)"
          >
            <!-- <div :close="close" class="w-[1000px] bg-blue-500">Hi</div> -->
            <slot :close="close" />
          </div>
        </PopoverPanel>
      </transition>
    </Teleport>
  </Popover>
</template>

<script setup>
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  PopoverOverlay,
} from "@headlessui/vue";

import { ref, defineProps } from "vue";
// eslint-disable-next-line no-unused-vars
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const props = defineProps({
  id: String,
  isOpen: Boolean,
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "auto",
  },
  teleport: {
    type: Boolean,
    default: false,
  },
  buttonClass: String,
  placement: {
    type: String,
    default: "bottom",
  },
  canOverflow: {
    type: Boolean,
    default: false,
  },
});
const { floatingStyles } = useFloating(reference, floating, {
  //strategy: "fixed",
  placement: props.placement,
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>

<script>
export default {
  provide() {
    return {
      position: "popover",
    };
  },
  mounted() {
    if (this.isOpen) {
      setTimeout(() => {
        this.$refs.reference.$el.click();
      }, 50);
    }
  },
};
</script>

<style module lang="scss">
.popoverPanel {
  composes: panel from "@/styles/panels.module.scss";
}
</style>
