import moment from "moment";
import { nanoid } from "nanoid";

export function availableAddons() {
  return [
    //"links",
    "description",
    "priority",
    //"completion",
    "schedule",
    "input",
    "output",
    "sorting",
    "anchor",
  ]
}

export function initHistory(entry, user_id) {

  return {
    id: nanoid(),
    user_id: user_id,
    entry_id: entry.id,
    field: null,
    before: {},
    after: {},
    created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
  };
}

export function addonBlueprint(type, target, context = null) {

  if (!target) {
    console.trace();
    console.error('addonBlueprint: target is required');
    return;
  }

  switch (type) {
    case "description":
      return {
        id: nanoid(),
        entry_id: target.id,
        content: "",
      };
    case "priority":
      return {
        id: nanoid(),
        entry_id: target.id,
        level: 2,
      };
    /*case "completion":
      return {
        id: nanoid(),
        entry_id: target.id,
        completed: false,
        completed_at: null,
      };*/
    case "schedule":
      return {
        id: nanoid(),
        entry_id: target.id,
        date: moment().local().format("YYYY-MM-DD"),
        time: null,
        duration: null,
        recurrence: null,
      };
    case "output":
      return {
        id: nanoid(),
        entry_id: target.id,
        type: 'list',
        filters: [{ key: "links", op: "any", val: [target.id] }],
        grouping: {},
        sorting: [],
        custom_sorting: [],
        settings: {},
      };
    /*case "sorting":
      return {
        id: nanoid(),
        entry_id: target.id,
        data: [{ field: ["date", "created"], dir: 1 }],
      };*/
    case "input":
      return {
        id: nanoid(),
        schema: {
          links: { op: "set", val: [target.id] }
        },
        created_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        updated_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      };
    case "aspect":
      return {
        id: nanoid(),
        entry_id: target.id,
        color: 'gray',
      };
    case "anchor":
      return {
        id: nanoid(),
        entry_id: target.id,
        position: context.$store.getters.entries.filter((e) => e.anchor).length,
      };
    case "cover":
      return {
        id: nanoid(),
        entry_id: target.id,
        type: 'external',
        url: '',
      };
    case "sense":
      return {
        id: nanoid(),
        space_id: target.space_id,
        entry_id: target.id,
        name: target.name + ' ' + (target.senses?.length + 1),
        color: target.color ? target.color : 'gray',
        filters: target.output?.filters && target.output.filters.length ? JSON.parse(JSON.stringify(target.output.filters)) : [{ key: "links", op: "any", val: [target.id] }],
        aggregates: [{ key: "count", op: "gt", val: [0] }]
      };
    case "routine":
      var id = nanoid();
      return {
        id: id,
        entry_id: target.id,
        frequency: 'daily',
        target: 1,
        value: 0,
        logs: [],
        created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
      };
    case "routine_log":
    case "routine_logs":
      return {
        id: nanoid(),
        routine_id: target.routine.id,
        completed: null,
        completed_at: null,
        created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
      };
    case "time_track":
    case "time_tracks":
    case "time_tracking":
      return {
        id: nanoid(),
        space_id: target.space_id,
        entry_id: target.id,
        user_id: context.user ? context.user.id : context.$store.getters.user.id,
        start_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        end_at: null,
      };
    case "template":
      return {
        id: nanoid(),
        space_id: target.space_id,
        entry_id: target.id,
        icon: 'puzzle',
        name: context.template ? context.template.name : target.name,
        description: context.template ? context.template.description : "",
        tags: [],
        data: {},
        entries: [],
        created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
      };
    case "custom_field":
    case "custom_fields":
      return {
        id: nanoid(),
        space_id: context?.space?.id || target?.space_id,
        entry_id: target?.id,
        name: '',
        type: 'text',
        position: context?.custom_fields?.length || 0,
      };
    case "status":
    case "statuses":
      return {
        id: nanoid(),
        space_id: context?.space?.id || target?.space_id,
        entry_id: target?.id,
        color: 'gray',
        name: '',
        type: 'custom',
        position: context?.statuses?.length || 0,
      };
    case "procrastination":
      return {
        id: nanoid(),
        entry_id: target?.id,
        count: 0
      };
  }
}

export function statusBlueprint(entry, obj) {
  return {
    id: nanoid(),
    space_id: obj?.space?.id || entry?.space_id,
    entry_id: entry?.id,
    color: 'gray',
    name: '',
    type: 'custom',
    position: obj?.statuses?.length || 0,
  };
}
export function customFieldBlueprint(entry, obj) {
  return {
    id: nanoid(),
    space_id: obj?.space?.id || entry?.space_id,
    entry_id: entry?.id,
    name: '',
    type: 'text',
    position: obj?.custom_fields?.length || 0,
  };
}
export function customValueBlueprint(entry, customField) {
  return {
    id: nanoid(),
    custom_field_id: customField.id,
    entry_id: entry?.id,
    value: ""
  };
}