<template>
  <input
    ref="reference"
    :value="internalTimeAsLocal"
    @input="internalTimeAsLocal = $event.target.value"
    type="text"
    @focus="handleOnFocus"
    @blur="handleOnBlur"
    @keydown.enter.stop.prevent="$refs.reference.blur()"
    data-test="time_picker_input"
    v-bind="$attrs"
  />
  <div
    ref="floating"
    v-if="showPopover"
    :class="$style.popover"
    :style="floatingStyles"
  >
    <div
      v-for="(_time, key) in times"
      :key="key"
      :ref="_time"
      @click.prevent="time = toUTC(_time)"
      :data-test="'time_picker_set_' + _time"
    >
      <slot name="timeSuggestion" :timeLocal="_time">
        <button :class="$style.buttonTimeSuggestion">
          {{ _time }}
        </button>
      </slot>
    </div>
  </div>
  <!-- <PopoverHelper>
    <template #button>
      <input v-model="time" type="text" />
      <div :class="$style.button">
        {{ time }}
      </div>
    </template>
    <template v-slot="{ close }">
      
    </template>
  </PopoverHelper> -->
</template>

<script setup>
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: String,
  },
  watch: {
    time() {
      this.setInternalTimeAsLocal();
    },
    showPopover(val) {
      if (val) {
        setTimeout(() => {
          const utcTime = this.$moment.utc(
            this.$moment().format("YYYY-MM-DD") +
              " " +
              (this.time ? this.time : this.$moment().format("HH:mm:ss")),
          );

          const scrollToTime = utcTime
            .minute(Math.floor(utcTime.minute() / 15) * 15)
            .format("HH:mm");

          if (this.$refs && this.$refs[scrollToTime])
            this.$refs[scrollToTime][0].scrollIntoView({
              behavior: "instant",
              block: "start",
            });
        }, 0);
      }
    },
  },
  mounted() {
    this.setInternalTimeAsLocal();
  },
  computed: {
    time: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    times() {
      return Array.from({ length: 24 }, (_, hour) => {
        return ["00", "15", "30", "45"].map((minute) => {
          return `${String(hour).padStart(2, "0")}:${minute}`;
        });
      }).flat();
    },
  },
  methods: {
    toUTC(time) {
      if (!this.isValidTime(time)) {
        return null;
      }

      return this.$moment(this.$moment.utc().format("YYYY-MM-DD") + " " + time)
        .utc()
        .format("HH:mm:ss");
    },
    setInternalTimeAsLocal() {
      if (this.time === null) {
        this.internalTimeAsLocal = "";
        return;
      }

      this.internalTimeAsLocal = this.$moment
        .utc(this.$moment.utc().format("YYYY-MM-DD") + " " + this.time)
        .local()
        .format("HH:mm");
    },
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },

    handleOnFocus() {
      this.showPopover = true;
      this.$refs.reference.select();
    },
    handleOnBlur() {
      this.delayShowPopover(false);
      if (this.internalTimeAsLocal === "") {
        this.time = null;
        return;
      }
      if (!this.isValidTime(this.internalTimeAsLocal)) {
        this.setInternalTimeAsLocal();
        return;
      }
      if (this.time != this.toUTC(this.internalTimeAsLocal)) {
        this.time = this.toUTC(this.internalTimeAsLocal);
        this.setInternalTimeAsLocal();
      }
    },
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    },
  },
  data() {
    return {
      internalTimeAsLocal: "",
      showPopover: false,
    };
  },
};
</script>

<style module lang="scss">
.input {
}

.popover {
  composes: panel from "@/styles/panels.module.scss";
  max-height: 20rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--ps-base-background-color);
  z-index: 10;
}

.buttonTimeSuggestion {
  @apply flex items-center gap-1;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: var(--ps-font-size-sm);
  line-height: var(--ps-line-height-sm);
  &:hover {
    background-color: var(--ps-base-tinted-color);
  }
}
</style>
